import React, { useEffect, useRef, useState } from "react";
import Grid from "@toast-ui/react-grid";
import '../toast/toastGrid.css';
import { useNewDmpProductionPlan } from "../stores/newWriteDmp";

function DmpDataProductionPlan({updateProductionPn, DmpMgmtHisNo}) {
    const gridRef = useRef(null);

    console.log("받아온 값 확인합니다. ===============", updateProductionPn, DmpMgmtHisNo);


    const { setNewProductionPlandataSets } = useNewDmpProductionPlan();


    const [cellValues, setCellValues] = useState([]);

    //값을 modify로 불러온 후 다시 수정할때 쓰이는 변수
    // const {setProductionDataSets} = useProductionDataSetsStore();

    useEffect(() => {
        if (updateProductionPn && updateProductionPn.Prod) {
            const formattedData = updateProductionPn.Prod.map(item => ({
                DmpPrdNo:item.DmpPrdNo,
                DmpNo:item.DmpNo,
                dataType: item.DtaNm,
                count: item.ProCnt,
                proPlan: item.ProPlan,
                InsDt: item.InsDt,
                UpdDt: item.UpdDt,
                delete: '삭제',
            }));
            setCellValues(formattedData);
        } else {
            setCellValues([]); // 또는 초기값을 설정
        }


    }, [updateProductionPn]);


    const columns = [
        {
            header: '데이터타입명',
            name: 'dataType',
            editor:
           DmpMgmtHisNo ? false :       {
               type: 'select',
               options: {
                   listItems: [
                       { text: 'DATABASE_MI', value: 'DATABASE_MI' },
                       { text: 'PROJECT', value: 'PROJECT' },
                       { text: 'PROJECT_FILE', value: 'PROJECT_FILE' },
                       { text: 'NORMALIZATION', value: 'NORMALIZATION' }
                   ]
               }
           },
            // editor: 'text',
            width: 300,
        },
        {
            header: '개수',
            name: 'count',
            editor: DmpMgmtHisNo ? false : 'text',
            width: 300
        },
        {
            header: '생산계획',
            name: 'proPlan',
            editor: DmpMgmtHisNo ? false : 'text',
            width: 300
        },
        {
            header: 'Delete',
            name: 'delete',

            formatter: ({ row }) => DmpMgmtHisNo
                ? `<span></span>`
                : `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`,
            width: 100,
        },
    ];
    const handleAddRow = () => {
        const newRow = {
            id: Date.now(), // Using current timestamp as a unique ID
            dataType: '-',
            count: '-',
            proPlan: '-',
        };
        setCellValues(prevList => [...prevList, newRow]);
    };


    const handleDeleteRow = (rowKey) => {
        console.log("로우 키 확인", rowKey);
        const parsedRowKey = parseInt(rowKey, 10);
        if (isNaN(parsedRowKey)) {
            console.error("Invalid rowKey:", rowKey);
            return;
        }
        setCellValues(prevList => prevList.filter(row => row.rowKey !== parsedRowKey));
    };


    useEffect(() => {
        const grid = gridRef.current.getInstance();
        grid.on('click', (ev) => {
            if (ev.targetType === 'cell' && ev.nativeEvent.target.className.includes('btn-delete')) {
                const rowKey = ev.nativeEvent.target.getAttribute('data-row-key');
                handleDeleteRow(rowKey);
            }
        });

        if (Array.isArray(cellValues) && cellValues.length > 0) {
            setTimeout(() => {
                setNewProductionPlandataSets(cellValues);  // 상태 업데이트
            }, 10);  // 비동기 처리로 타이밍 조정
        }
    }, [cellValues, setNewProductionPlandataSets]);

    const handleAfterChange = (ev) => {
        console.log('셀 값이 변경됨:', ev);

        if (ev && ev.changes && ev.changes.length > 0) {
            const updatedData = [...cellValues];

            ev.changes.forEach(({ rowKey, columnName, value }) => {
                if (updatedData[rowKey]) {
                    updatedData[rowKey] = { ...updatedData[rowKey], [columnName]: value };
                }
            });

            setCellValues(updatedData);
        }
    };

    // const handlePaste = (ev) => {
    //     const { changes } = ev;
    //     changes.forEach(change => {
    //         const { rowKey, columnName, value } = change;
    //         console.log(`Row: ${rowKey}, Column: ${columnName}, Value: ${value}`);
    //     });
    // };

    return (
        <div className="toast-container">
            { (!DmpMgmtHisNo) && (
                <div className="row-btn">
                    <button className="public-data-addrow" type="button"onClick={handleAddRow}>
                        행추가
                    </button>
                </div>
            )}

            <Grid
                data={cellValues}
                ref={gridRef}
                rowHeight={'auto'}
                bodyHeight={300}
                columns={columns}
                rowHeaders={['rowNum']}
                editable={true}  // 셀 편집 가능
                copyOptions={{ useFormattedValue: true }}  // 복사-붙여넣기 가능하게 설정
                onAfterChange={handleAfterChange}
            />
        </div>
    );
}

export default DmpDataProductionPlan;
