import React, {useState} from "react";
import ToastGrid from "../../toast/toastGrid";
import './detailPage.css';


function OpenDetailPage() {
    
    // const [showPage, setShowPage] = useState(false);
    // const openDataHandler = () => {
    //     setShowPage(true)
    // }

    return(
        <>
            <div className="dmpRealData-banner-container">
                <div className="dmpRealData-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmpRealData-img02" alt="search Logo" />
                    <div className="RealDatabanner-text">
                        <span>공개데이터(상세)</span>
                    </div>
                    <div className="dmpRealData-description">
                        <div className="dmpRealData-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터> 데이터 등록</span>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="RealData-management-title">
                        <h1>데이터 등록</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="RealData-object-info">
                        <div className="RealData-object-info-title">
                            <span className="RealData-info">DMP 정보</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-task-title">
                            <div className="RealData-task-wrapper">
                                <button className="Real-check-obj" >등록 DMP 불러오기</button>

                                            <>
                                                <input type="text" className="edit--box"  placeholder="과제번호" />
                                                <input type="text" className="edit--box"  placeholder="과제명" />
                                                <input type="text" className="edit--box"  placeholder="연구책임자" />
                                            </>
                                <button className="Real-check-obj" >DMP 보기</button>
                            </div>
                            <div>
                                <span className="tast-des">* 등록된 DMP를 선택하면 DMP 정보가 자동으로 입력됩니다.</span>
                            </div>
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="dataCreate-container">
                        <div className="dataCreate-title">
                            <span>등록 대상 데이터</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <div className="dmpDataSets-container">
                                <React.Fragment >
                                    <div className="dataSets-Inputboxs">
                                        <button className="dataSet-bring" >DMP 데이터세트 불러오기</button>
                                        <div className="selectbox-container">
                                            <div className="DataPage-selectbox-check">
                                                <select
                                                    className="createDataPage"
                                                >
                                                    <option value=''>-- 구분 --</option>
                                                    <option value="시료데이터">시료데이터</option>
                                                    <option value="방법데이터">방법데이터</option>
                                                    <option value="결과데이터">결과데이터</option>
                                                </select>
                                                <select
                                                    className="createDataPage"
                                                    id="selectBox"
                                                >
                                                    <option value=''>-- 선택 * 필수 --</option>
                                                    <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                    <option value="Food, Processed">Food, Processed</option>
                                                    <option value="Food, Derived">Food, Derived</option>
                                                    <option value="Animal">Animal</option>
                                                    <option value="Human">Human</option>
                                                    <option value="Invertebrate">Invertebrate</option>
                                                    <option value="Microbe">Microbe</option>
                                                    <option value="Cell, Animal">Cell, Animal</option>
                                                    <option value="Cell, Human">Cell, Human</option>
                                                    <option value="Cell, Plant">Cell, Plant</option>
                                                    <option value="etc">etc</option>
                                                </select>

                                                <input
                                                    type="text"
                                                    className="edit--box"
                                                    id="data-type-name"
                                                    name="DtaNm"
                                                    placeholder="데이터 타입명"
                                                />
                                                <input type="text"
                                                       className="edit--box"
                                                       id="data-type-desc"
                                                       name="DtaDs"
                                                       placeholder="데이터 타입 설명"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="datePage-targetData-des">
                                        <span className="tast-des">* 등록된 DMP 데이터세트를 선택하면 데이터세트가 자동으로 입력됩니다.</span>
                                        <span className="tast-des">* TYPE은 문자열:"STRING", 숫자:"NUMERIC", 날짜:"DATE", 파일명:"FILE" 로 사용을 권장합니다.</span>
                                        <ToastGrid  />
                                    </div>
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="RealDataSend-title">
                        {/*<div className="OpenData-send">*/}

                        {/*    <button className="Realsend02-dmp" onClick={() => openDataHandler()}>*/}
                        {/*            공개데이터 신청하기*/}
                        {/*    </button>*/}
                        {/*    {*/}
                        {/*        showPage && (*/}
                        {/*            <ApplicationPage setShowPage = {setShowPage}/>*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*</div>*/}
                    </div>
                </section>
            </div>
        </>
    )
}

export default OpenDetailPage;
