import './applicationPage.css'
import React, {useState, useEffect} from "react";
import api from "../api";
function ApplicationPage({setShowPage, selectedProjects}) {

    const [currentDate, setCurrentDate] = useState("");
    const [responsiblePerson, setResponsiblePerson] = useState("");

    const [testdata, setTestdata] = useState(null);

    const cancelHandler = () => {
        setShowPage(false);
    }

    // 컴포넌트가 렌더링될 때 현재 날짜 설정
    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1
        const day = String(today.getDate()).padStart(2, "0");

        setCurrentDate(`${year}년 ${month}월 ${day}일`);



    }, []);


    // 이용책임자 입력 값 변경 처리
    const handleInputChange = (event) => {
        setResponsiblePerson(event.target.value);
    };


    const applicationData = {
        DataApplication: {
            DtaSbmSeqNo: 456,
            RespUserNo: "U001",
            RespUserNm: "홍길동",
            Subject: "데이터 사용 주제",
            Purpose: "데이터 사용 목적",
            UsagePlan: "데이터 사용 계획",
            StartDt: "2023-10-01",
            EndDt: "2023-12-31",
        },
        JointUsers: [
            {
                DtaSbmSeqNo: 456,
                Department: "IT 부서",
                UserNo: "U002",
                UserNm: "이몽룡",
                Email: "test@example.com",
                PhoneNumber: "010-1234-5678",
                StartDt: "2023-10-01",
                EndDt: "2023-12-31",
                RespFlag: "Y",
                Status: 1,
            }
        ],
        TermsFile: [
            {
                OriginNm: "terms.pdf",
                SaveNm: "terms_saved.pdf",
                FilePath: "/uploads/terms.pdf",
                Size: 1024,
            }
        ]
    };

    const applicationBtn = async () => {
        try {
            const response = await api.post(`/api/open/application`, applicationData, {
                withCredentials: true
            });
            setTestdata(response.data);
            console.log("신청 성공:", response.data);
        } catch (err) {
            console.error("신청 실패, API 확인 필요:", err);
        }
    };

    return (
        <div className="form-container">
            <h2>연구데이터 이용 신청서</h2>

            <div className="section">
                <div className="checkbox">
                    <span>※</span>
                    <label htmlFor="applicantInfo">신청자 정보</label>
                </div>

                <table className="applicant-info-table" border="1">
                    <tbody>
                    <tr>
                        <td rowSpan="2" className="main-title">이용책임자</td>
                        <td>소속</td>
                        <td><input type="text" value="" /></td>
                        <td>성명</td>
                        <td><input type="text" value="" /></td>
                    </tr>
                    <tr>
                        <td>전화번호</td>
                        <td><input type="text" value="" /></td>
                        <td>이메일</td>
                        <td><input type="text" value="" /></td>
                    </tr>

                    <tr>
                        <td rowSpan="5" className="main-title">공동 이용자</td>
                        <td>소속</td>
                        <td>성명</td>
                        <td>전화번호</td>
                        <td>이메일</td>
                    </tr>
                    <tr>
                        <td><input type="text" value="" /></td>
                        <td><input type="text" value="" /></td>
                        <td><input type="text" value="" /></td>
                        <td><input type="text" value="" /></td>
                    </tr>
                    <tr>
                        <td><input type="text" value="" /></td>
                        <td><input type="text" value="" /></td>
                        <td><input type="text" value="" /></td>
                        <td><input type="text" value="" /></td>
                    </tr>
                    <tr>
                        <td><input type="text" value="" /></td>
                        <td><input type="text" value="" /></td>
                        <td><input type="text" value="" /></td>
                        <td><input type="text" value="" /></td>
                    </tr>
                    <tr>
                        <td><input type="text" value="" /></td>
                        <td><input type="text" value="" /></td>
                        <td><input type="text" value="" /></td>
                        <td><input type="text" value="" /></td>
                    </tr>
                    </tbody>
                </table>

                <div className="notice">
                    ※ 연구데이터 이용자 전원 "연구데이터 이용 서약서" 확인 및 제출 필수
                </div>
            </div>



            <div className="section">
                <div className="checkbox">
                    <span>※</span>
                    <label htmlFor="applicationDetails">신청사항</label>
                </div>
                <table className="application-details-table" border="1">
                    <tbody>
                    <tr>
                        <td style={{ textAlign: 'center' }}>주제</td>
                        <td colSpan="3" style={{ padding: '10px' }}>
                            <input type="text" style={{  marginBottom: '0px' }} />
                        </td>
                    </tr>
                    {/* 이용 목적 체크박스 */}
                    <tr>
                        <td style={{ textAlign: 'center' }}>이용목적</td>
                        <td style={{ textAlign: 'center' }}>
                            <input type="checkbox" /> 연구기획
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <input type="checkbox" /> 정책개발
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <input type="checkbox" /> 교육자료
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <input type="checkbox" /> 학술연구
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <input type="checkbox" /> 논문/출판
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <input type="checkbox" /> 기타 (
                            <input type="text" style={{ width: "40px" }} /> )
                        </td>
                    </tr>
                    {/* 이용 기간 */}
                    <tr>
                        <td style={{ textAlign: 'center' }}>이용기간</td>
                        <td colSpan="6" style={{ textAlign: 'center' }}>
                            2024년 <input type="text" style={{ width: "50px", marginBottom: '0px' }} /> 월{" "}
                            <input type="text" style={{ width: "50px", marginBottom: '0px' }} /> 일 ~ 2024년{" "}
                            <input type="text" style={{ width: "50px", marginBottom: '0px' }} /> 월{" "}
                            <input type="text" style={{ width: "50px", marginBottom: '0px' }} /> 일
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan="4" className="main-title" style={{ textAlign: 'center' }}>신청데이터</td>
                        <td style={{ textAlign: 'center' }}>종류</td>
                        <td style={{ textAlign: 'center' }}>명칭</td>
                        <td style={{ textAlign: 'center' }}>과제명/연구책임자</td>
                    </tr>
                    <tr>
                        <td style={{ padding: '10px' }}><input type="text" value= {selectedProjects[0]?.DtaSetNm}  style={{ width: "85%",  marginBottom: '0px' }}/></td>
                        <td style={{ padding: '10px' }}><input type="text" value= {selectedProjects[0]?.DtaNm} style={{ width: "85%",  marginBottom: '0px' }} /></td>
                        <td style={{ padding: '10px' }}><input type="text" value= {selectedProjects[0]?.ProjNm} style={{ width: "85%",  marginBottom: '0px' }} /></td>
                    </tr>
                    <tr>
                        <td style={{ padding: '10px' }}><input type="text" value= {selectedProjects[1]?.DtaSetNm} style={{ width: "85%",  marginBottom: '0px' }} /></td>
                        <td style={{ padding: '10px' }}><input type="text" value= {selectedProjects[1]?.DtaNm} style={{ width: "85%",  marginBottom: '0px' }}/></td>
                        <td style={{ padding: '10px' }}><input type="text" value= {selectedProjects[1]?.ProjNm} style={{ width: "85%",  marginBottom: '0px' }}/></td>
                    </tr>
                    <tr>
                        <td style={{ padding: '10px' }}><input type="text" value= {selectedProjects[2]?.DtaSetNm} style={{ width: "85%",  marginBottom: '0px' }}/></td>
                        <td style={{ padding: '10px' }}><input type="text" value= {selectedProjects[2]?.DtaNm} style={{ width: "85%",  marginBottom: '0px' }}/></td>
                        <td style={{ padding: '10px' }}><input type="text" value= {selectedProjects[2]?.ProjNm} style={{ width: "85%",  marginBottom: '0px' }}/></td>
                    </tr>
                    <tr>
                        <td rowSpan="4" style={{ textAlign: 'center' }}>신청데이터 이용계획</td>
                        <td colSpan="6" style={{ padding: '10px' }}>
                            <textarea style={{ width: "100%", height: "100px" }}></textarea>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>


            <div className="footer">
                <p>{currentDate}</p>
                <div className="signature">
                    <span>이용책임자: </span>
                    <input
                        type="text"
                        value={responsiblePerson}
                        onChange={handleInputChange}
                        placeholder="이용책임자 이름을 입력하세요"
                    />
                </div>
            </div>
            <div className="application-btn">
                <button className="application-apply" onClick={applicationBtn}>
                    신청
                </button>
                <button onClick={cancelHandler} className="application-cancel">
                    취소
                </button>
            </div>
        </div>
    );
}




export  default ApplicationPage;
