import React, { useEffect, useState } from "react";
import api from '../../api';

function DmpRealDataSend() {
    const [dataList, setDataList] = useState([]);
    const [dataCount, setDataCount] = useState(0); // 수정: 총 데이터 개수 상태값
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 번호
    const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
    const pageSize = 10; // 한 페이지에 표시할 데이터 수
    const pageGroupSize = 10; // 한 번에 표시할 페이지 번호 수 (1-10, 11-20 등)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await api.get(`/api/data/datalist?kw&ps=${currentPage}&pl=${pageSize}`, {
                    withCredentials: true
                });
                setDataCount(res.data.pages.Total); // 총 데이터 개수 설정
                setDataList(res.data.item); // 현재 페이지 데이터 목록
                setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [currentPage]);

    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize, dataCount); // 마지막 페이지일 경우 남은 데이터만큼 가져옴
        return dataList.slice(0, endIndex - startIndex);
    };


    // 페이지네이션 렌더링 함수
    const renderPagination = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${i === currentPage ? 'active' : ''}`}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => setCurrentPage(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => setCurrentPage(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };


    return (
        <>
            <div className="dmpReallist-container">
                <div className="dmpReallist-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmpReallist-img02" alt="search Logo" />
                    <div className="banner-Realtext">
                        <span>데이터 제출</span>
                    </div>
                    <div className="dmpReallist-description">
                        <div className="dmpReallist-description02">
                            <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 > 데이터 목록</span>
                        </div>
                    </div>
                </div>
                <div className="dmpReallist-inputbox">
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                    <input type="text" placeholder="검색어를 입력해주세요." />
                </div>
                <div>
                    <div className="btn-Realcontainer">
                        <div>총 {dataCount}건</div>
                    </div>
                    <div className="dmpReallist-start_line"></div>

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                        <tr className="dmpReallist-title">
                            <th>과제번호</th>
                            <th>데이터셋/ 설명</th>
                            <th>연구 책임자</th>
                            <th>제출 상태</th>
                        </tr>
                        </thead>
                        <tbody>
                        {getPaginatedData().map((row) => (
                            <tr key={row.DtaSbmSeqNo} className="dmpReallist-Data">
                                <td>{row.ProjNo}</td>
                                <td>{row.DtaSetNm} / {row.DtaDs}</td>
                                <td>{row.RspnEmpNm}</td>
                                <td>{row.dmpStatus || "미제출"}</td> {/* 제출 상태가 없을 경우 "미제출" 표시 */}
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/* 페이지네이션 추가 */}
                    {renderPagination()}
                </div>
            </div>
        </>
    );
}

export default DmpRealDataSend;
