import React, { useEffect, useState, useRef } from 'react';
import '../dmpCreate/dmpcreate.css';
import DataManagementGrid from "../../dmpDataManagement/dataManagementGrid";
import LastDmpShow from "../../lastDmp/lastDmpShow";
import DataPublicGrid from "../../dmpDataPublic/dataPublicGrid";
import TaskCheck from "../../taskCheck/taskCheck";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import DmpDataProductionPlan from "../../dmpDataProductionPlan/dmpDataProductionPlan";
import DmpModifyToastGrid from "../../dmpModify/dmpModifyToast";
import {
    useNewDmpManagementPlan,
    useNewDmpProductionPlan,
    useNewDmpPublicPlan,
    useNewDmpToastData
} from "../../stores/newWriteDmp";
import api from "../../api";
function DmpHisPageView() {
    const { DmpHisNo } = useParams();


    const navigate = useNavigate();

    // 로딩 상태와 데이터 상태 관리
    const [allDmpData, setAllDmpData] = useState(null); // null로 초기화하여 데이터가 없는 상태를 구분

    const [updateProNo, setUpdateProNo] = useState("");
    const [updateProName, setUpdateProName] = useState("");
    const [updateProLeader, setUpdateProLeader] = useState("");
    const [modifyMethodSummary, setModifyMethodSummary] = useState("");
    const [modifyMethodAnalysis, setModifyMethodAnalysis] = useState("");

    const [showLastDmp, setShowLastDmp] = useState(false);
    const [showObjCheck, setShowObjCheck] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [modifySummaryData, setModifySummaryData] = useState([]);
    const [imageSrc, setImageSrc] = useState(`${process.env.PUBLIC_URL}/bg_file_select.png`);

    //생산 계획 값 저장 modify에서 사용 !!
    const {newProductionPlandataSets} = useNewDmpProductionPlan();
    //관리 계획 값 저장 new!!
    //const { newManagementPlandataSets } = useNewDmpManagementPlan();
    //공개계획 값 저장
    //const { newPublicPlandataSets } = useNewDmpPublicPlan();

    // 가져온 데이터 setData 입니다.
    const dataTypes = ["시료데이터","방법데이터","결과데이터"];

    const lastSummaryItem = modifySummaryData[modifySummaryData.length - 1]?.Summary;
    const lastDataType = lastSummaryItem?.DtaSetNm;
    const currentTypeIndex = dataTypes.indexOf(lastDataType);
    const nextDataTypeIndex = (currentTypeIndex + 1) % dataTypes.length;
    const nextDataType = dataTypes[nextDataTypeIndex];


    console.log("newProductionPlandataSetsallDmpDataallDmpDataallDmpDataallDmpData=================", allDmpData);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`/api/history/detail?DmpHisNo=${DmpHisNo}&PrdYear=2024`, {
                    withCredentials: true,
                });
                setAllDmpData(response.data);
            } catch (err) {
                console.error("dmp 데이터를 불러오는 중 오류 발생:", err);
            }
        };

        fetchData();
    }, [DmpHisNo]); // id가 바뀔 때마다 데이터를 새로 불러오도록 설정


    useEffect(() => {
        if (allDmpData?.DmpMain) {
            setUpdateProNo(allDmpData.DmpMain?.ProjNo || '');
            setUpdateProName(allDmpData.DmpMain?.ProjNm || '');
            setUpdateProLeader(allDmpData.DmpMain?.RspnEmpNm || '');
            setModifyMethodSummary(allDmpData.DmpMain?.RsrcSmm || '');
            setModifyMethodAnalysis(allDmpData.DmpMain?.RsrcAnlyPlan || '');
            setModifySummaryData(allDmpData?.SumData || []); // 서머리 데이터 설정
        }
    }, [allDmpData]);

    const objHandler = () => {
        alert("수정페이지에서는 과제선택이 불가합니다.")
    }
    const lastHandler = () => {
        alert("수정페이지에서는 지난 DMP를 불러올 수 없습니다.")
    }

    // 파일 업로드 처리
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // 추가 데이터 처리
    const handleAddDataSet = () => {
        const newDataSets = dataTypes.map(type => ({
            DataMain: [
            ],
            Summary: {
                id: Date.now() + Math.random(), // 고유한 ID 생성
                DtaSetNm: type,
                DmpNo: allDmpData.DmpMain.DmpNo,
                DtaSetNmSub: "",
                DtaNm: "",
                DtaDs: "",
                DmpFlg: "Y",
            },
        }));

        setModifySummaryData(prevData => [...prevData, ...newDataSets]);
    };


    // 요약 데이터 업데이트 처리
    const handleUpdateChange = (id, event) => {
        console.log("아이디 값 확인", id);
        const newValue = event.target.value;

        setModifySummaryData(prevData =>
            prevData.map(dataSet => {
                if (dataSet.Summary.DtaSetNo === id || dataSet.Summary.id === id) {
                    // 조건에 맞는 데이터셋만 업데이트
                    return {
                        ...dataSet,
                        Summary: {
                            ...dataSet.Summary,
                            DtaSetNm: newValue
                        }
                    };
                }
                // 나머지 데이터셋은 변경하지 않고 그대로 반환
                return dataSet;
            })
        );
    };


    const handleRequiredChange = (id, event) => {
        const newValue = event.target.value;
        setModifySummaryData(prevData =>
            prevData.map(dataSet => {
                if (dataSet.Summary.DtaSetNo === id || dataSet.Summary.id === id) {
                    return {
                        ...dataSet,
                        Summary: {
                            ...dataSet.Summary,
                            DtaSetNmSub: newValue, // DtaSetNmSub을 업데이트
                        }
                    }
                }
                // 나머지 데이터셋은 변경하지 않고 그대로 반환
                return dataSet;
            })
        );
    };

    const handleInputChange = (id, event) => {
        const newValue = event.target.value;
        setModifySummaryData(prevData =>
            prevData.map(dataSet => {
                if (dataSet.Summary.DtaSetNo === id || dataSet.Summary.id === id) {
                    return {
                        ...dataSet,
                        Summary: {
                            ...dataSet.Summary,
                            DtaNm: newValue, // DtaNm을 업데이트
                        },
                    }
                }
                return dataSet;
            })
        );
    };

    const handleInputdescChange = (id, event) => {
        const newValue = event.target.value;
        setModifySummaryData(prevData =>
            prevData.map(dataSet => {
                if (dataSet.Summary.DtaSetNo === id || dataSet.Summary.id === id) {
                    return {
                        ...dataSet,
                        Summary: {
                            ...dataSet.Summary,
                            DtaDs: newValue, // DtaDs을 업데이트
                        },
                    }
                }
                return  dataSet;
            })
        );
    };

    // 삭제 처리
    const handleDeleteDataSet = (id) => {
        setModifySummaryData(prevData =>
            prevData.filter(dataSet => dataSet.Summary.DtaSetNo !== id && dataSet.Summary.id !== id)
        );
    };

    const hisListHandler = () => {
        navigate(-1);  // 이전 페이지로 이동
    }

    return(
        <>
            <div className="dmp-banner-container">
                <div className="dmp-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmp-img02" alt="search Logo" />
                    <div className="banner-text">
                        <span>데이터 관리 계획</span>
                    </div>
                    <div className="dmp-description">
                        <div className="dmp-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 관리 계획 > 데이터 관리 계획 등록</span>
                        </div>
                    </div>
                </div>


                <section>
                    <div className="management-plan-title">
                        <h1>데이터 관리 계획</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="object-info">
                        <div className="object-info-title">
                            <span className="info">과제 정보</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="task-title">
                            <div className="task-wrapper">

                                <>
                                    <button className="check-obj" onClick={objHandler}>과제 선택</button>
                                    {showObjCheck && <TaskCheck />}
                                </>

                                {
                                    allDmpData?.DmpMain?.DmpNo && (
                                        <div key={allDmpData.DmpMain.DmpNo} style={{display : 'flex'}}>
                                            <input
                                                type="text"
                                                className="edit--box"
                                                value={updateProNo || ''}
                                                readOnly
                                                onChange={(e) => setUpdateProNo(e.target.value)}
                                                placeholder="과제번호"
                                            />
                                            <input
                                                type="text"
                                                className="edit--box"
                                                value={updateProName || ''}
                                                readOnly
                                                onChange={(e) => setUpdateProName(e.target.value)}
                                                placeholder="과제명"
                                            />
                                            <input
                                                type="text"
                                                className="edit--box"
                                                readOnly
                                                value={updateProLeader || ''}
                                                onChange={(e) => setUpdateProLeader(e.target.value)}
                                                placeholder="연구책임자"
                                            />
                                        </div>
                                    )}
                            </div>
                            <div>
                                <span>* 과제 번호를 선택하면 과제 정보가 자동으로 입력됩니다.</span>
                            </div>
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="research-method-info">
                        <div className="object-info-title">
                            <span className="info">연구 방법 요약</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="research-method-title">
                                <textarea
                                    type="text"
                                    value={modifyMethodSummary}
                                    readOnly
                                    onChange={(e) => setModifyMethodSummary(e.target.value)}
                                    className="edit--box"
                                    placeholder="연구방법을 서술형으로 작성하세요."
                                />
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="configuring-research-data-info">
                        <div className="object-info-title">
                            <span className="info">연구데이터 구성</span>
                        </div>
                        <div className="research-method-title">
                            <label htmlFor="fileDiagram">
                                <img id="imgDiagram" src={imageSrc} alt="Diagram" />
                            </label>
                            <input
                                type="file"
                                id="fileDiagram"
                                name="fileDiagram"
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                            />
                            <input type="text" id="txtDiagram" name="txtDiagram" style={{ display: 'none' }} />

                        </div>
                    </div>
                    <div className="start_line"></div>

                    {
                        <div className="data-set-container" >
                            {modifySummaryData.map(updateSummaryItem => (
                                <div className="data-set-info" key={updateSummaryItem.Summary.length}>
                                    <div className="data-set-title-wrapper">
                                        <div className="data-set-title">
                                            <span className="info">데이터 세트</span>
                                            <span className="required_color">*</span>
                                            {/*<button className="data-set-delete" onClick={() => handleDeleteDataSet(updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo)}>*/}
                                            {/*    <span>-</span>*/}
                                            {/*    <span>삭제</span>*/}
                                            {/*</button>*/}
                                        </div>
                                        <div className="selectbox-container">
                                            <div className="selectbox-check">
                                                <select
                                                    className="resultData"
                                                    disabled
                                                    // id={`selectBox-${updateSummaryItem.Summary.DtaSetNo}`}
                                                    value={updateSummaryItem.Summary.DtaSetNm}
                                                    onChange={(event) => handleUpdateChange(updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo, event)}

                                                >
                                                    <option value=''>-- 구분 --</option>
                                                    <option value="시료데이터">시료데이터</option>
                                                    <option value="방법데이터">방법데이터</option>
                                                    <option value="결과데이터">결과데이터</option>
                                                </select>
                                                <select
                                                    className="resultData"
                                                    id="selectBox"
                                                    disabled
                                                    value={updateSummaryItem.Summary.DtaSetNmSub}
                                                    onChange={(event) => handleRequiredChange( updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo, event)}
                                                >
                                                    <option value=''>-- 선택 * 필수 --</option>
                                                    <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                    <option value="Food, Processed">Food, Processed</option>
                                                    <option value="Food, Derived">Food, Derived</option>
                                                    <option value="Animal">Animal</option>
                                                    <option value="Human">Human</option>
                                                    <option value="Invertebrate">Invertebrate</option>
                                                    <option value="Microbe">Microbe</option>
                                                    <option value="Cell, Animal">Cell, Animal</option>
                                                    <option value="Cell, Human">Cell, Human</option>
                                                    <option value="Cell, Plant">Cell, Plant</option>
                                                    <option value="etc">etc</option>
                                                </select>

                                                <input
                                                    type="text"
                                                    className="edit--box"
                                                    readOnly
                                                    value={updateSummaryItem.Summary.DtaNm}
                                                    onChange={(event) => handleInputChange( updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo, event)}
                                                    id="data-type-name"
                                                    name="DtaNm"
                                                    placeholder="데이터 타입명"
                                                />
                                                <input type="text"
                                                       className="edit--box"
                                                       readOnly
                                                       value={updateSummaryItem.Summary.DtaDs}
                                                       onChange={(event) => handleInputdescChange( updateSummaryItem.Summary.id || updateSummaryItem.Summary.DtaSetNo, event)}
                                                       id="data-type-desc"
                                                       name="DtaDs"
                                                       placeholder="데이터 타입 설명"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="toastui-wrapper">
                                        <DmpModifyToastGrid MethodData={updateSummaryItem.Summary.DtaSetNm} DateSets={updateSummaryItem} DmpDataSetsHisNo = {allDmpData.DmpMain.DmpHisNo} />
                                    </div>
                                </div>
                            ))}
                            {/*<button className="data-set-add" type="button" onClick={handleAddDataSet}>{`+ ${nextDataType} 세트 양식 추가`}</button>*/}
                        </div>

                    }
                    <div className="start_line"></div>
                    <div className="data-management-info">
                        <div className="object-info-title">
                            <span className="info">생산 계획</span>
                            <span className="required_color">*</span>
                        </div>
                        {
                            allDmpData && (
                                <div>
                                    <DmpDataProductionPlan updateProductionPn = {allDmpData} DmpMgmtHisNo = {allDmpData.DmpMain.DmpHisNo}/>
                                </div>
                            )
                        }
                    </div>
                    <div className="start_line"></div>

                    <div className="data-analysis-info">
                        <div className="object-info-title">
                            <span className="info">연구데이터 분석 계획</span>
                            <span className="required_color">*</span>
                        </div>
                        {
                            allDmpData && (
                                <div className="research-method-title">
                                    <textarea type="text"
                                              className="edit--box"
                                              readOnly
                                              value={modifyMethodAnalysis}
                                              onChange={(e) => setModifyMethodAnalysis(e.target.value)}
                                              placeholder="연구데이터 분석 계획을 서술형으로 작성하세요."></textarea>
                                </div>
                            )
                        }


                    </div>
                    <div className="start_line"></div>
                    <div className="data-management-info">
                        <div className="object-info-title">
                            <span className="info">연구데이터 관리 계획</span>
                            <span className="required_color">*</span>
                        </div>

                        {
                            allDmpData && (
                                <div>
                                    <DataManagementGrid updateProductionPn = {allDmpData} DmpProductionHisNo = {allDmpData.DmpMain.DmpHisNo}  />
                                </div>
                            )
                        }
                    </div>
                    <div className="start_line"></div>

                    <div className="data-public-info">
                        <div className="object-info-title">
                            <span className="info">연구데이터 공개 계획</span>
                            <span className="required_color">*</span>
                        </div>

                        {
                            allDmpData && (
                                <div>
                                    <DataPublicGrid updateProductionPn = {allDmpData} DmpPublicHisNo = {allDmpData.DmpMain.DmpHisNo}/>
                                </div>
                            )
                        }

                    </div>
                    <div className="start_line"></div>
                </section>

                <div className="save-container">
                    {/*<button id="temporary-Storage">임시저장</button>*/}

                    <button id="dmp_list" onClick={hisListHandler}>
                        목록
                    </button>
                </div>

            </div>
        </>
    )
}
export default DmpHisPageView;