import React, {useEffect, useRef, useState} from "react";
import Grid from "@toast-ui/react-grid";
import 'tui-grid/dist/tui-grid.css';
import {usePublicDataStore} from "../stores/lastDmpData";
import {useNewDmpPublicPlan} from "../stores/newWriteDmp";

function DataPublicGrid({updateProductionPn, DmpPublicHisNo}) {

    const gridRef = useRef(null);
    const { setNewPublicPlandataSets } = useNewDmpPublicPlan();

    const [openData, setOpenData] = useState([]);



    //업데이트 전용 값
    // const {setPublicDataSets} = usePublicDataStore();

    useEffect(() => {
        if(updateProductionPn && updateProductionPn.Open) {
            const addList = updateProductionPn.Open.map(item => {
                return{
                    DmpNo:item.DmpNo,
                    DmpOpNo:item.DmpOpNo,
                    DmpPrdNo:item.DmpPrdNo,
                    datatype:item.DtaNm,
                    publictime:item.OpnTm,
                    externalTime:item.OpnPd,
                    publicmethod:item.OpnMth,
                    individualplan:item.OpnResn,
                    individualmethod:item.OpnOpnMth,
                    InsDt: item.InsDt,
                    UpdDt: item.UpdDt,
                    delete:'삭제',
                }
            })
            setOpenData(addList);
        }
        else {
            setOpenData([]); // 또는 초기값을 설정
        }
    }, [updateProductionPn]);


    const columns = [
        {
            header: '데이터타입명',
            name: 'dataType',
            editor: DmpPublicHisNo ? false : {
                type: 'select',
                options: {
                    listItems: [
                        { text: 'DATABASE_MI', value: 'DATABASE_MI' },
                        { text: 'PROJECT', value: 'PROJECT' },
                        { text: 'PROJECT_FILE', value: 'PROJECT_FILE' },
                        { text: 'NORMALIZATION', value: 'NORMALIZATION' }
                    ]
                }
            },
            width: 140,
        },
        {
            header: '내부공개시점',
            name: 'publictime',
            editor: DmpPublicHisNo ? false : 'text',
            width: 120
        },
        {
            header: '외부공개시점',
            name: 'externalTime',
            editor: DmpPublicHisNo ? false : 'text',
            width: 120
        },
        {
            header: '공개방법',
            name: 'publicmethod',
            editor: DmpPublicHisNo ? false : {
                type: 'select',
                options: {
                    listItems: [
                        { text: 'DMP 플랫폼', value: 'DMP 플랫폼' },
                        { text: '개별관리', value: '개별관리' }
                    ]
                }
            },
            lineHeight: '1.5',
            width: 120,
        },
        {
            header: '개발공개 계획',
            name: 'individualplan',
            editor: DmpPublicHisNo ? false : 'text',
            lineHeight: '1.5',
            width: 220,
        },
        {
            header: '개별공개 방법',
            name: 'individualmethod',
            editor: DmpPublicHisNo ? false : 'text',
            lineHeight: '1.5',
            width: 220,

        },
        {
            header: '행 삭제',
            name: 'delete',
            formatter: ({ row }) => DmpPublicHisNo
                ? `<span></span>`
                : `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`,
            width: 100,
        }

    ];

    const handleAddRow = () => {
        const newRow = {
            id: Date.now(), // Using current timestamp as a unique ID
            datatype: '-',
            publictime: '-',
            externalTime: '-',
            publicmethod: '-',
            individualplan: '',
            individualmethod: '',
        };
        setOpenData(prevList => [...prevList, newRow]);
    };

    const handleDeleteRow = (rowKey) => {
        const parsedRowKey = parseInt(rowKey, 10);
        if (isNaN(parsedRowKey)) {
            console.error("Invalid rowKey:", rowKey);
            return;
        }
        setOpenData(prevList => prevList.filter(row => row.rowKey !== parsedRowKey));
    };

    useEffect(() => {
        const grid = gridRef.current.getInstance();
        grid.on('click', (ev) => {
            if (ev.targetType === 'cell' && ev.nativeEvent.target.className.includes('btn-delete')) {
                const rowKey = ev.nativeEvent.target.getAttribute('data-row-key');
                handleDeleteRow(rowKey);
            }
        });

        if (Array.isArray(openData) && openData.length > 0) {
            setTimeout(() => {
                setNewPublicPlandataSets(openData);  // 상태 업데이트
            }, 10);  // 비동기 처리로 타이밍 조정
        }

    }, [openData, setNewPublicPlandataSets]);

    const handleAfterChange = (ev) => {
        console.log('셀 값이 변경됨:', ev);

        if (ev && ev.changes && ev.changes.length > 0) {
            const updatedData = [...openData];

            ev.changes.forEach(({ rowKey, columnName, value }) => {
                if (updatedData[rowKey]) {
                    updatedData[rowKey] = { ...updatedData[rowKey], [columnName]: value };
                }
            });

            setOpenData(updatedData);
        }
    };

    return (
        <div className="toast-container">
            { (!DmpPublicHisNo) && (
                <div className="row-btn">
                    <button className="public-data-addrow" type="button" onClick={handleAddRow}>
                        행추가
                    </button>
                </div>
            )}

            <Grid
                data={openData}
                ref={gridRef}
                rowHeight={'auto'}
                bodyHeight={300}
                columns={columns}
                rowHeaders={['rowNum']}
                editable={true}  // 셀 편집 가능
                copyOptions={{ useFormattedValue: true }}  // 복사-붙여넣기 가능하게 설정
                onAfterChange={handleAfterChange}
            />
        </div>
    );
}

export default DataPublicGrid;
