// src/homePage.js
import React, {useEffect, useState} from 'react';
import 'tui-grid/dist/tui-grid.css';
import './home.css'
import api from '../api';
import {useNavigate} from "react-router-dom";
import {useSearchHomeStore} from "../stores/dataSetsStore";

function HomePage() {
    const [dataValue, setdateValue] = useState({})
    // const [searchData, setSearchData] = useState("");

    const setSearchData = useSearchHomeStore((state) => state.setSearchHomeData);
    const searchData = useSearchHomeStore((state) => state.searchHomeData);

    const resetSearchData = useSearchHomeStore((state) => state.resetSearchHomeData);

    const navigate = useNavigate();

    const images = [
        `${process.env.PUBLIC_URL}/main-bg01.png`,
        `${process.env.PUBLIC_URL}/main-bg03.png`,
        `${process.env.PUBLIC_URL}/main-bg02.png`
    ]

    const [currentIndex, setCurrentIndex] = useState(0);


    useEffect(() => {
        // API 호출하여 데이터를 불러옴
        api.get('/api/dmp/count').then((res)=> setdateValue(res.data))
            .catch((error) => console.error("home api를 확인해보세요.", error));

        // 슬라이더 이미지 변경
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 4000);

        // 검색어 초기화
        resetSearchData();

        // 컴포넌트 언마운트 시 인터벌 정리
        return () => clearInterval(interval);
    }, [resetSearchData]);  // 의존성 배열에 `resetSearchData`를 추가

    const handlerHomeChange = (e) => {
        setSearchData(e.target.value);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            navigate("/dmpRealData/dmpRealDataList/dmpRealDataList")
        }
    }

    const handleSearchClick = () => {
        navigate("/dmpRealData/dmpRealDataList/dmpRealDataList")
    }


    return (
        <div className="App">
            <section className="main-img">
                <div className="slider-container">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className={`slider-image ${index === currentIndex ? 'active' : ''}`}
                        />
                    ))}
                        <div className="title-container">
                            <div className="main-title">
                                <h1>Welcome to</h1>
                                <h2>KFRI Database Repository</h2>
                            </div>
                            <div className="main-input-box">
                                <input
                                    type="text"
                                    placeholder="찾으시는 데이터를 입력해주세요."
                                    value={searchData}
                                    onChange={handlerHomeChange}
                                    onKeyPress={handleKeyPress} // 엔터키 이벤트 처리
                                />
                                <img
                                    src={`${process.env.PUBLIC_URL}/icon_search.png`}
                                    alt="search Logo"
                                    onClick={handleSearchClick} // 돋보기 아이콘 클릭 이벤트 처리
                                    style={{ cursor: 'pointer' }} // 커서 스타일을 포인터로 변경하여 클릭 가능하게 보이도록 설정
                                />
                            </div>
                            <div className="mainDataNumber">
                                <div className="registerDMP">
                                    <h2>등록된 DMP </h2>
                                    <span>{dataValue.count?.CntDmp}</span>
                                </div>
                                <div className="registerData">
                                    <h2>등록된 데이터 </h2>
                                    <span>{dataValue.count?.CntData}</span>
                                </div>
                                <div className="openDMP">
                                    <h2>공개된 DMP </h2>
                                    <span>{dataValue.count?.CntOpenData}</span>
                                </div>
                            </div>
                        </div>
                </div>
            </section>
            <section>
                <div className="majorServiceIntro">
                    <h1>
                        주요 서비스
                    </h1>
                    <span>KFRI 관련 데이터를 등록 할 수 있습니다.</span>
                    <div className="majorService-img">
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/btn_add.png`} alt="search Logo" />
                        </div>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/data-btn2.png`} style={{ width: "560px",height: "200px" }} alt="search Logo" />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="noticeIntro">
                    <div>
                        <div  className="notice-contents">
                            <h1>공지사항</h1>
                            <img src={`${process.env.PUBLIC_URL}/icon_notice.png`} alt="search Logo" />
                        </div>
                    </div>
                    <div className="div_notice">
                        <div>식품데이터플랫폼 사이트가 오픈되었습니다.</div>
                        <div >2022.01.01</div>
                    </div>
                        <span>더보기</span>
                        <img className="usermanual-img" src={`${process.env.PUBLIC_URL}/icon_usermanual.png`} alt="search Logo" />
                </div>
            </section>


        </div>
    )
}

export default HomePage;
