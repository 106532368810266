import React, {useEffect, useRef, useState} from "react";
import Grid from "@toast-ui/react-grid";
import '../toast/toastGrid.css';
import {useNewDmpManagementPlan} from "../stores/newWriteDmp";

function DataManagementGrid({updateProductionPn, DmpProductionHisNo}) {
    const gridRef = useRef(null);

    const { setNewManagementPlandataSets } = useNewDmpManagementPlan();

    const [mnCellValue, setMnCellValue] = useState([]);


    //연구데이터 관리 계획
    // const {setManagementDataSets} = useManagementDataStore()

    useEffect(() => {
        if(updateProductionPn && updateProductionPn.Mnmt) {
            const addList = updateProductionPn.Mnmt.map(item => ({
                    DmpMgmtNo:item.DmpMgmtNo,
                    DmpNo:item.DmpNo,
                    dataType:item.DtaNm,
                    method:item.MngMth,
                    DmpPrdNo:item.DmpPrdNo,
                    individualManagement:item.MngResn,
                    dataLocation:item.MngPos,
                    storagePeriod:item.MngPd,
                    periodExpires:item.MngOpnMth,
                    DelFlg:item.DelFlg,
                    InsDt: item.InsDt,
                    UpdDt: item.UpdDt,
                    delete:'삭제',
                }))
            setMnCellValue(addList);
        }
        else {
            setMnCellValue([]); // 또는 초기값을 설정
        }
    }, [updateProductionPn]);


    const columns = [
        {
            header: '데이터타입명',
            name: 'dataType',
            editor: DmpProductionHisNo ? false : {
                type: 'select',
                options: {
                    listItems: [
                        { text: 'DATABASE_MI', value: 'DATABASE_MI' },
                        { text: 'PROJECT', value: 'PROJECT' },
                        { text: 'PROJECT_FILE', value: 'PROJECT_FILE' },
                        { text: 'NORMALIZATION', value: 'NORMALIZATION' }
                    ]
                }
            },
            width: 140,
        },
        {
            header: '관리방법',
            name: 'method',
            editor: DmpProductionHisNo ? false : {
                type: 'select',
                options: {
                    listItems: [
                        { text: 'DMP 플랫폼', value: 'DMP 플랫폼' },
                        { text: '개별관리', value: '개별관리' }
                    ]
                }
            },
            width: 140
        },
        {
            header: '개별관리사유',
            name: 'individualManagement',
            editor: DmpProductionHisNo ? false : 'text',
            width: 140
        },
        
        {
            header: '데이터보관 위치',
            name: 'dataLocation',
            editor: DmpProductionHisNo ? false : 'text',
            width: 140
        },
        {
            header: '보관기간',
            name: 'storagePeriod',
            editor: DmpProductionHisNo ? false : 'text',
            width: 140
        },
        {
            header: '보관기간 만료 후 관리 정책',
            name: 'periodExpires',
            editor: DmpProductionHisNo ? false : 'text',
            width: 210
        },
        {
            header: '행 삭제',
            name: 'delete',
            formatter: ({ row }) => DmpProductionHisNo
                ? `<span></span>`
                : `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`,
            width: 100,
        }
    ];
    const handleAddRow = () => {
        const newRow = {
            id: Date.now(), // Using current timestamp as a unique ID
            dataType:'-',
            method:'-',
            individualManagement:'-',
            dataLocation:'-',
            storagePeriod:'-',
            periodExpires:'-',
        };
        setMnCellValue(prevList => [...prevList, newRow]);
    };

    const handleDeleteRow = (rowKey) => {
        const parsedRowKey = parseInt(rowKey, 10);
        if (isNaN(parsedRowKey)) {
            console.error("Invalid rowKey:", rowKey);
            return;
        }
        setMnCellValue(prevList => prevList.filter(row => row.rowKey !== parsedRowKey));
    };


    useEffect(() => {
        const grid = gridRef.current.getInstance();
        grid.on('click', (ev) => {
            if (ev.targetType === 'cell' && ev.nativeEvent.target.className.includes('btn-delete')) {
                const rowKey = ev.nativeEvent.target.getAttribute('data-row-key');
                handleDeleteRow(rowKey);
            }
        });

        if (Array.isArray(mnCellValue) && mnCellValue.length > 0) {
            setTimeout(() => {
                setNewManagementPlandataSets(mnCellValue);  // 상태 업데이트
            }, 8);  // 비동기 처리로 타이밍 조정
        }

    }, [mnCellValue, setNewManagementPlandataSets]);


    const handleGridUpdated = (ev) => {

        if (ev && ev.changes && ev.changes.length > 0) {
            const updatedData = [...mnCellValue];

            ev.changes.forEach(({ rowKey, columnName, value }) => {
                if (updatedData[rowKey]) {
                    updatedData[rowKey] = { ...updatedData[rowKey], [columnName]: value };
                }
            });

            setMnCellValue(updatedData);
        }
    };


    return (
        <div className="toast-container">
            { (!DmpProductionHisNo) && (
                <div className="row-btn">
                    <button className="public-data-addrow" type="button" onClick={handleAddRow}>
                        행추가
                    </button>
                </div>
            )}

            <Grid
                data={mnCellValue}
                ref={gridRef}
                columns={columns}
                rowHeight={'auto'}
                bodyHeight={300}
                rowHeaders={['rowNum']}
                editable={true}  // 셀 편집 가능
                copyOptions={{ useFormattedValue: true }}  // 복사-붙여넣기 가능하게 설정
                onAfterChange ={handleGridUpdated}
            />
        </div>
    );
}

export default DataManagementGrid;
