import './App.css';
import React from "react";
import {Routes, Route, useLocation} from "react-router-dom";
import HomePage from "./home/home";
import Header from "./header/header";
import Footer from './footer/footer';
import DmpcreatePage from "./dmpManagement/dmpCreate/dmpcreate";
import Dmplist from "./dmpManagement/dmpList/dmplist";
import Dmpsend from "./dmpManagement/dmpSend/dmpsend";
import DmpRealDataCreate from "./dmpRealData/dmpRealDataCreate/dmpRealDataCreate";
import DmpRealDataList from "./dmpRealData/dmpRealDataList/dmpRealDataList";
import DmpRealDataSend from "./dmpRealData/dmpRealDataSend/dmpRealDataSend";
import SubminDataStatus from "./adminPage/subminStatus/subminDataStatus";
import DmpObjCreate from "./dmpobj/dmpObjCreate";
import DmpDataProductionPlan from "./dmpDataProductionPlan/dmpDataProductionPlan";
import AdminDataPage from "./adminPage/adminDataPage";
import DataSubmissionStatus from "./adminPage/submissionStatus/dataSubmissionStatus";
import DmpAccessCheck from "./adminPage/dmpAccessCheck/dmpAccessCheck";
import Login from "./login/login";
import OpenData from "./openData/openData";
import OpenDetailPage from "./openData/detail/page";
import DmpRealDataModify from "./dmpRealData/dmpRealDataModify/dmpRealDataModify";
import DmpmodifyPage from "./dmpManagement/dmpModify";
import IntroPage from "./intro/intro";
import DmpBoard from "./board/board";
import DmpBoardDetail from "./board/boardDetail";
import {Helmet} from "react-helmet";
import DmpRealDataView from "./dmpRealData/dmpRealDataView/dmpRealDataView";
import DmpHisDataList from "./dmpRealData/dmpHisDataList/dmpHisDataList";
import DmpHisDataView from "./dmpRealData/dmpHisDataView/dmpHisDataView";
import DmpHisPageList from "./dmpManagement/dmpHisPageList/dmpHisPageList";
import DmpHisPageView from "./dmpManagement/dmpHisPageView/dmpHisPageView";

function App() {

    const location = useLocation();

    const hideAdminpage = location.pathname.includes('/adminPage');

  return (
          <div className="tttss">
              <Helmet>
                  <title>KFRI Database Repository</title>
                  <link rel="icon" href="/public/favicon.ico"/>
              </Helmet>
              <Routes>
                  <Route path="/adminPage/adminDataPage" element={<AdminDataPage />} />
                  <Route path="/adminPage/submintStatus/submintStatus" element={<SubminDataStatus />} />
                  <Route path="/adminPage/dataSubmissionStatus/dataSubmissionStatus" element={<DataSubmissionStatus/>} />
                  <Route path="/adminPage/dmpAccessCheck/dmpAccessCheck" element={<DmpAccessCheck/>} />
              </Routes>
              {!hideAdminpage && <Header />} {/* 조건에 맞지 않으면 Header 렌더링 */}
              <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/dmpManagement/dmpCreate/dmpcreate" element={<DmpcreatePage />} />
                  <Route path="/dmpManagement/dmpList/dmplist" element={<Dmplist />} />
                  <Route path="/dmpManagement/dmpSend/dmpsend" element={<Dmpsend />} />
                  <Route path="/openData/openData" element={<OpenData />} />
                  <Route path="/openData/detail/page" element={< OpenDetailPage/>} />
                  <Route path="/dmpRealData/dmpRealDataCreate/dmpRealDataCreate" element={<DmpRealDataCreate />} />

                  {/*dmp 이력 리스트 코드*/}
                  <Route path="/dmpManagement/dmpHisPageList/:DmpNo" element={<DmpHisPageList />} />
                  <Route path="/dmpManagement/dmpHisPageView/:DmpHisNo" element={<DmpHisPageView />} />


                  <Route path="/dmpManagement/dmpModify/:id" element={<DmpmodifyPage />} />
                  <Route path="/dmpRealData/dmpRealDataView/:DtaSbmSeqNo" element={<DmpRealDataView />} />
                  <Route path="/dmpRealData/dmpRealDataModify/:DtaSbmSeqNo" element={<DmpRealDataModify />} />
                  <Route path="/dmpRealData/dmpRealDataList/dmpRealDataList" element={<DmpRealDataList />} />
                  <Route path="/dmpRealData/dmpHisDataList/:DmpNo/:DtaSetNo" element={<DmpHisDataList />} />
                  <Route path="/dmpRealData/dmpHisDataView/:DmpNo/:DtaSetHisNo" element={<DmpHisDataView />} />

                  <Route path="/dmpRealData/dmpRealDataSend/dmpRealDataSend" element={<DmpRealDataSend />} />
                  <Route path="/dmpobj/dmpObjCreate" element={<DmpObjCreate />} />
                  <Route path="/dmpDataProductionPlan/dmpDataProductionPlan" element={<DmpDataProductionPlan />} />
                  <Route path="/login/login" element={<Login/>} />
                  <Route path="/intro/intro" element={<IntroPage />} />
                  <Route path="/board/:BoardSeq" element={<DmpBoard />} />
                  <Route path="/board/boardDetail" element={<DmpBoardDetail />} />

              </Routes>
              {!hideAdminpage && <Footer />} {/* 조건에 맞지 않으면 Footer 렌더링 */}


          </div>
  );
}

export default App;
