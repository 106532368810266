import React, { useEffect, useState } from "react";
import './lastDmpShow.css';
import api from '../api';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {useLastDataStore, useTaskDataStore} from "../stores/lastDmpData";

function LastDmpShow({ setShowHistory }) {
    // const [, setObjnumber] = useState("");
    // const [, setLeaderName] = useState("");
    // const [, setObjDate] = useState("");
    const [tableData, setTableData] = useState([]);
    const [isPopupOpen, setIsPopOpen] = useState(true);

    //검색에 필요한 상태값 변환
    const [searchValue, setSearchValue] = useState("");

    const [lastOriginalList, setLastOriginalList] = useState([]);

    const { setLastDataSets } = useLastDataStore();
    //과제선택
    const { setTaskDataSets} = useTaskDataStore();

    const closePopup = () => {
        setIsPopOpen(false);
    }

    useEffect(() => {
        const tmpYn = setShowHistory ? 'Y' : 'N';
        api.get(`/api/dmp/dmpload?TmpYn=${tmpYn}`, { withCredentials: true })
            .then((res) => {
                setTableData(res.data);
                setLastOriginalList(res.data);
            })
            .catch(error => {
                console.log("lastDMP의 api를 확인해보세요.", error);
            });
    }, [setShowHistory]);


    console.log("lastOriginalListlastOriginalList", lastOriginalList);

    const filterHandler = (e) => {
        const value = e.target.value;

        setSearchValue(value);

        const objFilter = lastOriginalList.filter((item) => item.ProjNo.toLowerCase().includes(value.toLowerCase())
        )
        setTableData(objFilter);
    }

    const PreviewHandler = async (id) => {
        await api.get(`/api/dmp/loaddmpall?dmpNo=${id}&PrdYear=2024`, { withCredentials: true })
            .then((res) => {
                setLastDataSets(res.data);
                setTaskDataSets("");
            })
            .catch(error => {
                console.log("공개 관리 데이터 api 확인 바랍니다.")
            });

        setIsPopOpen(false);
    }

    const downloadPdf = () => {
        const input = document.body;
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'px',
                    format: 'a4'
                });

                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('download.pdf');
            });
    };

    if (!isPopupOpen) return null;

    return (
        <div className="last-dmp-container">
            <div className="lastDmp-wrapper">
                <div className="last-dmp-title01">
                    <h1>{setShowHistory ? "임시저장 정보 불러와서 작성하기" : "지난 DMP 정보 불러와서 작성하기"}</h1>
                    <img
                        src={`${process.env.PUBLIC_URL}/btn_close.png`}
                        alt="close"
                        onClick={closePopup}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
                <div className="last-dmp-inputbox">
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                    <input type="text"
                           value={searchValue}
                           onChange={filterHandler}
                           placeholder= {setShowHistory ? "임시저장 정보를 검색해주세요." : "지난 DMP 정보를 검색해주세요." } />
                </div>

                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                    <tr className="last-dmp-title">
                        <th>과제번호</th>
                        <th>연구 책임자</th>
                        <th>작성일자</th>
                        <th>DMP 보기</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.map((row, index) => (
                        <tr key={index} className="last-dmp-Data">
                            <td>
                                <button type="button" onClick={() => PreviewHandler(row.DmpNo)}>
                                    {row.ProjNo}
                                </button>
                            </td>
                            <td>{row.RspnEmpNm}</td>
                            <td>{row.UpdDt}</td>
                            <td>
                                <button type="button" id="content-container" onClick={downloadPdf}>
                                    {row.BtnDMP}
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default LastDmpShow;
