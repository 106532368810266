import React, { useRef, useState, useEffect } from 'react';
import Grid from '@toast-ui/react-grid';
import 'tui-grid/dist/tui-grid.css';
import '../toast/toastGrid.css';
import { useNewDmpToastData } from "../stores/newWriteDmp";

const DmpToastGrid = ({ MethodData, DateSets, DmpDataSetsHisNo }) => {

    console.log("dmp 수정에서 확인하빈다", DateSets);


    const gridRef = useRef(null);
    const [sampleData, setSampleData] = useState([]); // 시료데이터 저장 배열
    const [methodData, setMethodData] = useState([]); // 방법데이터 저장 배열
    const [resultData, setResultData] = useState([]); // 결과데이터 저장 배열


    // Custom editor for handling shift + Enter
    class CustomTextEditor {
        constructor(props) {
            const el = document.createElement('textarea');
            el.style.width = '100%';
            el.style.height = '100%';
            el.value = String(props.value);
            this.el = el;
        }

        getElement() {
            return this.el;
        }

        getValue() {
            return this.el.value;
        }

        mounted() {
            this.el.focus();
            this.el.addEventListener('keydown', (e) => {
                if (e.key === 'Enter' && e.shiftKey) {
                    e.stopPropagation();  // Prevent grid from interpreting Enter as row submission
                }
            });
        }
    }

    // Columns definition with custom editor for description
    const columns = MethodData === "방법데이터" ? [
        { header: 'METHOD INDEX', name: 'DtaMtdIdx', editor: DmpDataSetsHisNo ? false : 'text', width: 200 },
        { header: 'METHOD NAME', name: 'DtaMtdNm', editor: DmpDataSetsHisNo ? false : 'text', width: 200 },
        {
            header: 'DATATYPE_DESCRIPTION',
            name: 'DtaDsc',
            editor: DmpDataSetsHisNo ? false : CustomTextEditor,
            width: 400,
            lineHeight: '1.5',
            whiteSpace: 'pre-wrap'
        },
        {
            name: 'delete',
            header: 'Delete',
            formatter: ({ row }) => DmpDataSetsHisNo
                ? `<span></span>`
                : `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`,
            // formatter: ({ row }) => `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`
        },
    ] : [
        { header: 'FIELD', name: 'DtaFld', editor: DmpDataSetsHisNo ? false : 'text', width: 200 },
        { header: 'TYPE', name: 'DtaTyp', editor: DmpDataSetsHisNo ? false : 'text', width: 200 },
        { header: 'UNIT', name: 'DtaUnit', editor: DmpDataSetsHisNo ? false : 'text', width: 200 },
        {
            header: 'DATATYPE_DESCRIPTION',
            name: 'DtaDsc',
            editor: DmpDataSetsHisNo ? false : CustomTextEditor,
            lineHeight: '1.5',
            whiteSpace: 'pre-wrap'
        },
        {
            name: 'delete',
            header: 'Delete',
            formatter: ({ row }) => DmpDataSetsHisNo
                ? `<span></span>`
                : `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`,
            // formatter: ({ row }) => `<button class="btn-delete" data-row-key="${row.rowKey}">Delete</button>`
        },
    ];

    // 데이터가 있을 시 처음 보여줄때
    useEffect(() => {
        if (DateSets.DataMain?.length > 0) {
            const sampleList = [];
            const methodList = [];
            const resultList = [];

            DateSets.DataMain.forEach(item => {
                if (MethodData === "시료데이터") {
                    sampleList.push({
                        DmpNo: item.DmpNo,
                        DtaSetNo: item.DtaSetNo,
                        DtaFld: item.DtaFld,
                        DtaTyp: item.DtaTyp,
                        DtaUnit: item.DtaUnit,
                        DtaDsc: item.DtaDsc,
                        delete: '삭제'
                    });
                } else if (MethodData === "방법데이터") {
                    methodList.push({
                        DmpNo: item.DmpNo,
                        DtaSetNo: item.DtaSetNo,
                        DtaMtdIdx: item.DtaMtdIdx,
                        DtaMtdNm: item.DtaMtdNm,
                        DtaDsc: item.DtaDsc,
                        delete: '삭제'
                    });
                } else if (MethodData === "결과데이터") {
                    resultList.push({
                        DmpNo: item.DmpNo,
                        DtaSetNo: item.DtaSetNo,
                        DtaFld: item.DtaFld,
                        DtaTyp: item.DtaTyp,
                        DtaUnit: item.DtaUnit,
                        DtaDsc: item.DtaDsc,
                        delete: '삭제'
                    });
                }
            });

            setSampleData(sampleList);
            setMethodData(methodList);
            setResultData(resultList);
        }
    }, [DateSets]);

    const handleAddRow = () => {
        // const newId = DateSets.DataMain[0].DtaNo
        const newId = Date.now();
        const newRow = MethodData === "방법데이터" ? {
            id: newId,
            DtaMtdIdx: '-',
            DtaMtdNm: '-',
            DtaDsc: "-",
            delete: '삭제'
        } : {
            id: newId,
            DtaFld: '-',
            DtaTyp: '-',
            DtaUnit: '-',
            DtaDsc: "-",
            delete: '삭제'
        };

        if (MethodData === "방법데이터") {
            setMethodData(prevState => {
                const updatedData = [...prevState, newRow];
                DateSets.DataMain = [...updatedData]; // `DataSet`에 직접 추가
                return updatedData;
            });
        } else if (MethodData === "시료데이터") {
            setSampleData(prevState => {
                const updatedData = [...prevState, newRow];
                DateSets.DataMain = [...updatedData]; // `DataSet`에 직접 추가
                return updatedData;
            });
        } else if (MethodData === "결과데이터") {
            setResultData(prevState => {
                const updatedData = [...prevState, newRow];
                DateSets.DataMain = [...updatedData]; // `DataSet`에 직접 추가
                return updatedData;
            });
        }

    };

    const handleDeleteRow = (rowKey) => {
        const parsedRowKey = parseInt(rowKey, 10);
        if (isNaN(parsedRowKey)) {
            console.error("Invalid rowKey:", rowKey);
            return;
        }

        if (MethodData === "방법데이터") {
            setMethodData(prevData => {
                const updatedData = prevData.filter(row => row.rowKey !== parsedRowKey);
                DateSets.DataMain = updatedData; // `DataSet`에서 삭제된 데이터 반영
                return updatedData;
            });
        } else if (MethodData === "시료데이터") {
            setSampleData(prevData => {
                const updatedData = prevData.filter(row => row.rowKey !== parsedRowKey);
                DateSets.DataMain = updatedData; // `DataSet`에서 삭제된 데이터 반영
                return updatedData;
            });
        } else if (MethodData === "결과데이터") {
            setResultData(prevData => {
                const updatedData = prevData.filter(row => row.rowKey !== parsedRowKey);
                DateSets.DataMain = updatedData; // `DataSet`에서 삭제된 데이터 반영
                return updatedData;
            });
        }
    };

    useEffect(() => {
        const grid = gridRef.current.getInstance();
        grid.on('click', (ev) => {
            if (ev.targetType === 'cell' && ev.nativeEvent.target.className.includes('btn-delete')) {
                const rowKey = ev.nativeEvent.target.getAttribute('data-row-key');
                handleDeleteRow(rowKey);
            }
        });

    }, []);

    const handleGridUpdated = (ev) => {
        if (ev && ev.changes && ev.changes.length > 0) {
            let updatedData = []; // DateSets.DataMain에 저장할 최종 데이터
            ev.changes.forEach(({ rowKey, columnName, value }) => {
                console.log("키와 이름 확인", rowKey, columnName, value);

                const handleDataUpdate = (prevData, setData) => {
                    const currentRowType = ev.changes.find(change => change.rowKey === rowKey && change.columnName === 'DtaTyp')?.value;
                    const typeIsNumeric = currentRowType === 'NUMERIC' || (getDataByRow(rowKey, prevData)?.DtaTyp === 'NUMERIC');
                    const typeIsString = currentRowType === 'STRING' || (getDataByRow(rowKey, prevData)?.DtaTyp === 'STRING');

                    // type이 'NUMERIC'이면 unit은 숫자만 입력 가능
                    if (typeIsNumeric && columnName === 'DtaUnit') {
                        if (isNaN(value)) {
                            alert('숫자만 입력이 가능합니다.');
                            setData(prev => prev.map(row =>
                                row.rowKey === rowKey ? { ...row, DtaUnit: '' } : row // DtaUnit 값을 빈 문자열로 설정
                            ));
                            return; // 잘못된 값 입력 시 더 이상 처리하지 않음
                        }
                    }

                    // type이 'STRING'이면 unit은 숫자가 아닌 값만 입력 가능
                    if (typeIsString && columnName === 'DtaUnit') {
                        if (!isNaN(value)) {
                            alert('문자열만 입력이 가능합니다.');
                            setData(prev => prev.map(row =>
                                row.rowKey === rowKey ? { ...row, DtaUnit: '' } : row // DtaUnit 값을 빈 문자열로 설정
                            ));
                            return; // 잘못된 값 입력 시 더 이상 처리하지 않음
                        }
                    }

                    // 'type' 컬럼 값이 'NUMERIC' 또는 'STRING'일 때 처리
                    if (columnName === 'DtaTyp' && (value === 'NUMERIC' || value === 'STRING')) {
                        setData(prev => {
                            return prev.map((row, index) =>
                                index === rowKey ? { ...row, [columnName]: value } : row
                            );
                        });
                    }

                    // 데이터 업데이트 로직
                    setData(prevData => {
                        const updatedList = prevData.map((row, index) =>
                            index === rowKey ? { ...row, [columnName]: value } : row
                        );
                        updatedData = updatedList; // 최종 업데이트 데이터
                        return updatedList;
                    });
                };

                if (MethodData === "시료데이터") {
                    handleDataUpdate(sampleData, setSampleData);
                } else if (MethodData === "방법데이터") {
                    handleDataUpdate(methodData, setMethodData);
                } else if (MethodData === "결과데이터") {
                    handleDataUpdate(resultData, setResultData);
                }
            });

            // 업데이트된 데이터를 최종적으로 DateSets.DataMain에 저장
            DateSets.DataMain = updatedData;
        }
    };

// Helper function to get a specific row's data from the current dataset
    const getDataByRow = (rowKey, data) => {
        return data.find(row => row.rowKey === rowKey);
    };



    return (
        <div className="toast-container">
            { (!DmpDataSetsHisNo) && (
                <div className="row-btn">
                    <button className="public-data-addrow" onClick={handleAddRow}>
                        행추가
                    </button>
                </div>
                )
            }

            <Grid
                ref={gridRef}
                data={MethodData === "방법데이터" ? methodData : MethodData === "시료데이터" ? sampleData : resultData}
                columns={columns}
                rowHeight={'auto'}
                bodyHeight={300}
                rowHeaders={['rowNum']}
                editable={true}  // 셀 편집 가능
                copyOptions={{ useFormattedValue: true }}  // 복사-붙여넣기 가능하게 설정
                onAfterChange ={handleGridUpdated}
            />
        </div>
    );
};

export default DmpToastGrid;
