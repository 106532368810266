import React, {useState, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import api from "../api";

function DmpBoardDetail() {
        const location = useLocation();

    // const { BoardSeq } = useParams(); // URL 파라미터에서 BoardSeq 값을 가져옴

        // URLSearchParams 객체를 사용하여 쿼리 파라미터 가져오기
        const queryParams = new URLSearchParams(location.search);
        const boardSeq = queryParams.get('BoardSeq');
        const seq = queryParams.get('seq');
        const [detailValue, setDetailValue] = useState({});
        const [boardFile, setBoardFile] = useState({});
        const [boardName, setBoardName] = useState({});

    useEffect(() => {

            api.get(`/api/board/detail?BoardSeq=${boardSeq}&Seq=${seq}`).then(res => {
                    setDetailValue(res.data.detail);
                    setBoardFile(res.data.file);
            });

            api.get(`/api/board?BoardSeq=${boardSeq}`).then(res => {
            if (boardSeq === '1' || boardSeq === '2' || boardSeq === '3' || boardSeq === '4') {
                setBoardName(res.data);
            }
        });
    }, []); // BoardSeq 값이 변경될 때마다 실행


    const closeFile = (indexRemoveFile) => {
        // const fileToRemove = setBoardFile[indexRemoveFile];

        // 파일을 물리적으로 삭제하지 않고 상태를 업데이트
        // setBoardFile((prevFiles) =>
        //     prevFiles.map((item, index) => {
        //         if (index === indexRemoveFile) {
        //             return {
        //                 ...item,
        //                 Fstatus: "delete", // 삭제 상태 추가
        //                 DtaSbmSeqNo : boardFile.DtaSbmSeqNo,
        //             };
        //         }
        //         return item;
        //     })
        //);
        alert("삭제되었습니다.")
    }


    const handleBoardUpload = () => {
        console.log("board 파일 업로드 파일");
    }

    return(
        <>
            <div className="dmpRealData-banner-container">
                <div className="dmpRealData-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmpRealData-img02" alt="search Logo" />
                    <div className="RealDatabanner-text">
                        <span>{boardName.BoardDesc}</span>
                    </div>
                    <div className="dmpRealData-description">
                        <div className="dmpRealData-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 소개> {boardName.BoardNm}</span>
                        </div>
                    </div>
                </div>

                <section>
                    <div className="RealData-management-title">
                        <h1>게시글 등록</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="RealData-object-info">
                        <div className="RealData-object-info-title" style={{width: '140px'}}>
                            <span className="RealData-info">제목</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-task-title">
                            <div className="RealData-task-wrapper">

                                        <>
                                            <input type="text" className="edit--box" style={{width:'700px'}} placeholder="연구책임자" value={detailValue.Title}/>
                                        </>

                            </div>
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="dataCreate-container">
                        <div className="dataCreate-title">
                            <span>본문</span>
                            <span className="required_color">*</span>
                        </div>
                        <div>
                            <div className="dmpDataSets-container">
                                <div className="dataSets-Inputboxs">
                                    <div className="selectbox-container">
                                        <div className="DataPage-selectbox-check">
                                            <textarea
                                                type="text"
                                                className="edit--box"
                                                id="data-type-name"
                                                name="DtaNm"
                                                style={{
                                                    width: '900px', // 너비 조정
                                                    height: '400px', // 높이 조정
                                                    padding: '10px', // 안쪽 여백 추가
                                                    fontSize: '16px', // 글꼴 크기 조정
                                                    lineHeight: '1.6', // 줄 간격 조정
                                                    borderRadius: '5px', // 모서리 둥글게 처리
                                                    border: '1px solid #ccc', // 테두리 스타일
                                                    backgroundColor: '#f9f9f9', // 배경색 추가
                                                    whiteSpace: 'pre-wrap', // 줄바꿈과 공백을 유지
                                                }}
                                                placeholder="데이터 타입명"
                                                value={detailValue.Contents}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="start_line"></div>
                    <div className="RealDataSend-title">
                        <div className="RealData-send" style={{width: '145px'}}>
                            <span>첨부파일</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="RealData-sendFile-container">
                            <div className="research-method-title">
                                <label htmlFor="fileDiagram">
                                    <img id="imgDiagram" src={`${process.env.PUBLIC_URL}/btn_file_select.png`} alt="Diagram" />
                                </label>
                            </div>
                            <input type="file"  id="fileDiagram" name="dataSubmitFiles" multiple className="search-input" placeholder="파일 이름" />
                            {/*boardFile.map((item, index) => (*/}
                                    <div className="fileName">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/btn_close.png`}
                                            alt="close"
                                            onClick={() => closeFile(boardFile.BoardListFileSeq)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <div>
                                            <p>{boardFile?.OrgFileNm}</p> {/* 파일의 이름을 표시합니다 */}
                                        </div>
                                    </div>
                                {/*))*/}
                            <div className="RealData-btnContainer">
                                <button className="RealData-save-button" onClick={handleBoardUpload}>
                                    전송
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="RealData-save-btn-container">
                    <button id="dmp_list">
                        <Link to="/dmpRealData/dmpRealDataList/dmpRealDataList"> 목록</Link>
                    </button>
                    <button id="save-btn">
                       삭제
                    </button>
                    <button id="save-btn">
                        수정
                    </button>
                </div>
            </div>
        </>
    )
}

export default DmpBoardDetail
