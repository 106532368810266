import '../dmpRealData/dmpRealDataList/dmpRealDataList.css';
import React from "react";

function IntroPage() {

    return(
        <>
            <div className="dmpReallist-container">
                <div className="dmpReallist-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmpReallist-img02" alt="search Logo" />
                    <div className="banner-Realtext">
                        <span>소개</span>
                    </div>
                    <div className="dmpReallist-description">
                        <div className="dmpReallist-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 소개 > 소개</span>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "60px" }}>
                        <h2 style={{ fontWeight: "bold" }}>소개</h2>
                    <div className="dmpReallist-start_line"></div>

                    <div className="div_cont">
                        <span style={{ fontWeight: "bold" }}>ㅇ 소개</span>
                        <br />
                        <br />
                        디지털 뉴노멀 시대를 맞아 데이터 중심 R&D 활성화로 데이터의 활용가치가 상승함에 따라 국내에서도 데이터 통합 사업이 활성화되고 있습니다. 식품분야 빅데이터 시장의 성장에 맞춰 식품 데이터 통합 및 공유‧활용 체계 마련은 미래식품산업에 대한 국가적 경쟁력을 갖추기 위해 중요하다 할 것입니다.
                        <br />
                        <br />
                        식품연은 ‘식품데이터 통합사업’을 통해 연구과정에서 생산된 핵심 데이터의 확보와 데이터 분석･공동 활용으로 식품과학기술 분야 연구혁신을 가속화할 것입니다. 이를 통해 데이터 기반 연구를 활성화하고 연구자 중심의 식품데이터 생태계를 구축하여 식품 R&D 혁신을 창출하고자 합니다.
                        <br />
                        <br />
                        이의 기반으로 식품 데이터를 체계적으로 수집․표준화하는 관리체계를 구축하고 데이터 공동활용을 위한 플랫폼을 구축하였습니다.
                        <br />
                        식품데이터 플랫폼에서는
                        <ul>
                            <li>연구데이터 모델링</li>
                            <li>식품분야별 표준 데이터모델</li>
                            <li>웹기반의 데이터 수집·관리</li>
                            <li>대용량 데이터레이크 구축</li>
                            <li>기계학습 기반 데이터 분석</li>
                            <li>데이터 품질향상, 증강, 매핑</li>
                            <li>데이터 통계분석</li>
                        </ul>
                        이 가능합니다.
                        <br />
                        <br />
                        <br />
                        <span style={{ fontWeight: "bold" }}>ㅇ KFRI Repository 개념도</span>
                        <br />
                        <br />
                        <img
                            src={`${process.env.PUBLIC_URL}/KFRI_Repository_Concept.png`}
                            style={{ width: "1100px", marginBottom: '40px' }}
                            alt="개념도"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default IntroPage;
