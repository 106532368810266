import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api';

const BoardComponent = () => {
    const { BoardSeq } = useParams(); // URL 파라미터에서 BoardSeq 값을 가져옴
    const [boardValue, setBoardValue] = useState([]);
    const [boardName, setBoardName] = useState({});
    const [activeButton, setActiveButton] = useState('교육/세미나');
    const navigate = useNavigate();

    useEffect(() => {
        if (BoardSeq) {
            api.get(`/api/board/list?BoardSeq=${BoardSeq}`).then(res => {
                if (BoardSeq === '1' || BoardSeq === '2' || BoardSeq === '3' || BoardSeq === '4') {
                    setBoardValue(res.data.list);
                }
            });
            api.get(`/api/board?BoardSeq=${BoardSeq}`).then(res => {
                if (BoardSeq === '1' || BoardSeq === '2' || BoardSeq === '3' || BoardSeq === '4') {
                    setBoardName(res.data);
                }
            });
        }
    }, [BoardSeq]); // BoardSeq 값이 변경될 때마다 실행

    //BoardSeq=1&seq=14
    const handleRowClick = (BoardSeq, Seq) => {
        // 동적 경로로 이동 (DtaSbmSeqNo가 포함된 URL)
        navigate(`/board/boardDetail?BoardSeq=${BoardSeq}&seq=${Seq}`);
    };

    const handleButtonClick = (buttonLabel, newBoardSeq) => {
        setActiveButton(buttonLabel);
        navigate(`/board/${newBoardSeq}`);
    };

    console.log("borar 데이터", boardValue);

    return (
        <div className="dmpReallist-container">
            <div className="dmpReallist-banner-img">
                <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmpReallist-img02" alt="search Logo" />
                <div className="banner-Realtext">
                    <span>{boardName.BoardDesc}</span>
                </div>
                <div className="dmpReallist-description">
                    <div className="dmpReallist-description02">
                        <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                        <span> > 소개 > {boardName.BoardNm}</span>
                    </div>
                </div>
            </div>

            <div className="dmpReallist-inputbox"></div>

            {/* BoardSeq에 따라 다른 UI 렌더링 */}
            {BoardSeq === '1' ? (
                <div style={{ height: '100vh' }}>
                    <div className="btn-Realcontainer">
                        {/* <div>총 {dataCount}건</div> */}
                    </div>
                    <div className="main-input-box">
                        <img src={`${process.env.PUBLIC_URL}/btn_newwrite.png`} alt="new write" />
                    </div>
                    <div className="dmpReallist-start_line"></div>

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                        <tr className="dmpReallist-title">
                            <th>제목</th>
                            <th>작성자</th>
                            <th>등록일</th>
                            <th>조회</th>
                        </tr>
                        </thead>
                        <tbody>
                        {boardValue?.map((row, index) => (
                            <tr className="dmpReallist-Data" key={index}>
                                <td onClick={() => handleRowClick(row.BoardSeq, row.BoardListSeq)}>
                                    {row.Title}
                                </td>
                                <td>{row.UserNm}</td>
                                <td className="update-page">
                                    {row.InsDt}
                                </td>
                                <td>{row.ReadCnt}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <>
                    <div className="dmpSeminarData">
                        <button
                            className={activeButton === '교육/세미나' ? 'active' : ''}
                            onClick={() => handleButtonClick('교육/세미나', 2)}
                        >
                            교육/세미나
                        </button>
                        <button
                            className={activeButton === '법령/정책자료' ? 'active' : ''}
                            onClick={() => handleButtonClick('법령/정책자료', 3)}
                        >
                            법령/정책자료
                        </button>
                        <button
                            className={activeButton === '자료실' ? 'active' : ''}
                            onClick={() => handleButtonClick('자료실', 4)}
                        >
                            자료실
                        </button>
                    </div>

                    <div style={{ height: '100vh' }}>
                        <div className="btn-Realcontainer">
                            {/* <div>총 {dataCount}건</div> */}
                        </div>
                        <div className="main-input-box">
                            <img src={`${process.env.PUBLIC_URL}/btn_newwrite.png`} alt="new write" />
                        </div>
                        <div className="dmpReallist-start_line"></div>

                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr className="dmpReallist-title">
                                <th>제목</th>
                                <th>작성자</th>
                                <th>등록일</th>
                                <th>조회</th>
                            </tr>
                            </thead>
                            <tbody>
                            {boardValue?.map((row, index) => (
                                <tr className="dmpReallist-Data" key={index}>
                                    <td onClick={() => handleRowClick(row.BoardSeq, row.BoardListSeq)}>
                                        {row.Title}
                                    </td>
                                    <td>{row.UserNm}</td>
                                    <td className="update-page">
                                        {row.InsDt}
                                    </td>
                                    <td>{row.ReadCnt}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
};

export default BoardComponent;
