import React, {useEffect, useState, useRef} from 'react';
import './dmpcreate.css'
import DataManagementGrid from "../../dmpDataManagement/dataManagementGrid";
import LastDmpShow from "../../lastDmp/lastDmpShow";
import {
    useTaskDataStore,
    useLastDataStore
} from "../../stores/lastDmpData";
import DataPublicGrid from "../../dmpDataPublic/dataPublicGrid";
import TaskCheck from "../../taskCheck/taskCheck";
import api from '../../api';
import {Link, useNavigate} from "react-router-dom";
import DmpDataProductionPlan from "../../dmpDataProductionPlan/dmpDataProductionPlan";
import {
    useNewDmpManagementPlan,
    useNewDmpProductionPlan,
    useNewDmpPublicPlan,
} from "../../stores/newWriteDmp";
import DmpToastGrid from "../../dmpToast/dmpToast";

function DmpcreatePage() {

    const navigate = useNavigate();

    const taskRef = useRef(null);
    const methodSummaryRef = useRef(null);
    const newDataSummaryRef = useRef(null);
    const newDataSetRef = useRef(null);
    const analysisPlanRef = useRef(null);
    const productionPlanRef = useRef(null);
    const managementPlanRef = useRef(null);
    const publicPlanRef = useRef(null);


    const [showLastDmp , setShowLastDmp] = useState(false);
    const [showObjCheck, setShowObjCheck] = useState(false);
    const [showHistory, setShowHistory] = useState(false);

    //새로운 summary 생성
    const [newDataSummary, setNewDataSummary] = useState([]);

    // 가져온 데이터 setData 입니다.
    const dataTypes = ["시료데이터","방법데이터","결과데이터"];

    //새로운 summary 제목에 필요한 버튼 생성
    const newDataType = newDataSummary[newDataSummary.length - 1]?.DtaSetNm;
    const currentNewTypeIndex = dataTypes.indexOf(newDataType);
    const newDataSummaryName = (currentNewTypeIndex + 1) % dataTypes.length;
    const resultSummaryType = dataTypes[newDataSummaryName];

    // 과제 선택 부분
    const tastdataSets = useTaskDataStore(state => state.taskdataSets);
    const lastDataCellValue = useLastDataStore(state => state.lastdataSets);

    // 이미지 불러오기 부분
    const [imageSrc, setImageSrc] = useState(`${process.env.PUBLIC_URL}/bg_file_select.png`);

    //지난 데이터 불러오기 시 과제명, 과제번호, 설명
    const [createProjNo, setCreateProjNo] = useState("" );
    const [createProName, setCreateProName] = useState("")
    const [rspnEmpNm, setRspnEmpNm] = useState('');
    const [createRspnEmpNo, setCreateRspnEmpNo] = useState("")
    const [createDateDmpNo, setCreateDateDmpNo] = useState("")
    const [createDateDmpNm, setCreateDateDmpNm] = useState("")

    // 지난 데이터 불러오기 시 요약을 입력할때
    const [methodSummary, setMethodSummary] = useState("");

    // 지난 데이터 불러오기 시 연구데이터 분석 계획
    const [analysisPlan, setAnalysisPlan] = useState("");

    //지난 데이터 불러오기 시 데이터 세트 저장
    const [lastDataSets, setLastDataSets] = useState([]);

    //Summary 데이터 세트 값 저장



    //생산 계획 값 저장 new !!
    const {newProductionPlandataSets, resetProductionPlandataSets} = useNewDmpProductionPlan();
    //관리 계획 값 저장 new!!
    const { newManagementPlandataSets,setNewManagementPlandataSets } = useNewDmpManagementPlan();
    //공개계획 값 저장
    const { newPublicPlandataSets, setNewPublicPlandataSets } = useNewDmpPublicPlan();


/////////////////////////////////////////////////////////////////////////////// 지난 DMP 불러오기 코드 부분
    //등록 시 지난DMP 불러오기를 하면 과제 번호도 바껴야함 같이 전부 세팅 되어야한다.

    //과제 선택에서 데이터 가져온 값 데이터 저장
    useEffect(() => {
        const lastItem = tastdataSets[tastdataSets.length - 1];

        if (lastItem) {
            setCreateProjNo(tastdataSets[0]?.ProjNo);
            setCreateProName(tastdataSets[0]?.ProjNm);
            setRspnEmpNm(tastdataSets[0]?.RspnEmpNm);
            setCreateRspnEmpNo(tastdataSets[0]?.RspnEmpNo);
            setCreateDateDmpNo(tastdataSets[0]?.DataEmpNo);
            setCreateDateDmpNm(tastdataSets[0]?.DataEmpNm);
            setMethodSummary("");
            setAnalysisPlan("");
            setLastDataSets("");
        }
        else {
            setCreateProjNo(lastDataCellValue.DmpMain?.ProjNo);
            setCreateProName(lastDataCellValue.DmpMain?.ProjNm);
            setRspnEmpNm(lastDataCellValue.DmpMain?.RspnEmpNm);
            setCreateRspnEmpNo(lastDataCellValue.DmpMain?.RspnEmpNo);
            setCreateDateDmpNo(lastDataCellValue.DmpMain?.DataEmpNo);
            setCreateDateDmpNm(lastDataCellValue.DmpMain?.DataEmpNm);
            setMethodSummary(lastDataCellValue.DmpMain?.RsrcSmm);
            setAnalysisPlan(lastDataCellValue.DmpMain?.RsrcAnlyPlan)
            setLastDataSets(lastDataCellValue.SumData);
        }
    }, [tastdataSets, lastDataCellValue]);

    // useEffect(() => {
    //     resetProductionPlandataSets();
    //     setCreateProjNo("");
    //     setCreateProName("");
    //     setRspnEmpNm("");
    //     setCreateRspnEmpNo("");
    //     setCreateDateDmpNo("");
    //     setCreateDateDmpNm("");
    //     setMethodSummary("");
    //     setAnalysisPlan("");
    //     setLastDataSets("");
    // }, []);


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }

    };

    console.log("new데이터 확인", newDataSummary);


    // 핸들러 함수: 입력 필드의 변화를 관리
    const newSummaryhandleChange = (id, key, value) => {

        if (Array.isArray(lastDataSets) && lastDataSets.length > 0) {
            setLastDataSets(prevData =>
                prevData.map(item =>
                    // 두 조건이 모두 참인 경우에만 업데이트
                    item.Summary?.DtaSetNo === id || item.Summary?.id === id
                        ? { Summary: { ...item.Summary, [key]: value }, DataMain: [] }
                        : item
                )
            );
        }

        else {
            setNewDataSummary(prevData =>
                prevData.map(item =>
                    item.Summary?.id === id ? { Summary: { ...item.Summary, [key]: value }, DataMain: []}
                        : item
                )
            );
        }

    };

    // 데이터 세트 추가 핸들러
    const handleCreateAddDataSet = () => {
        console.log("버튼 동작!!");
        if(Array.isArray(lastDataSets) && lastDataSets.length > 0) {
            setLastDataSets(prevData => [
                ...prevData,
                ...dataTypes.map(type => ({
                    Summary:{
                        id: Date.now() + Math.random(), // 고유한 ID 생성
                        DtaSetNm: type, // dataTypes 배열의 각 값을 할당
                        DtaSetNmSub: '', // 초기값
                        DtaNm: '', // 초기값
                        DtaDs: '', // 초기값
                        DmpFlg: "Y",
                    },
                    DataMain: [],
                }))
            ]);
        }
        setNewDataSummary(prevData => [
            ...prevData,
            ...dataTypes.map(type => ({
                Summary:{
                    id: Date.now() + Math.random(), // 고유한 ID 생성
                    DtaSetNm: type, // dataTypes 배열의 각 값을 할당
                    DtaSetNmSub: '', // 초기값
                    DtaNm: '', // 초기값
                    DtaDs: '', // 초기값
                    DmpFlg: "Y",
                },
                DataMain: [],
            }))
        ]);
    };

    const newHandleDeleteDataSet = (id) => {
        setNewDataSummary(newDataSummary.filter(dataSet => dataSet.Summary.id !== id));
    };

    // 지난 데이터 불러오기 컴포넌트

    const toggleLastDmp = () => {
        setShowLastDmp(!showLastDmp);
    }
    const toggleHistoryDmp = () => {
        setShowHistory(!showHistory);
    }

    const toggleObjCheck = () => {
        setShowObjCheck(!showObjCheck);
    }


    //검증 함수
    const validateData = () => {
        if (lastDataSets && lastDataSets.length > 0) {
            return true; // 유효성 통과
        }
        // && !lastDataCellValue.DmpMain?.analysisPlan
        // && !lastDataCellValue.DmpMain?.RsrcSmm

        // 과제 선택 유효성 검사
        // || lastDataCellValue.DmpMain?.some(item => !item.ProjNo || !item.ProjNm || !item.RspnEmpNm)
        if (tastdataSets?.length === 0 || tastdataSets?.some(item => !item.ProjNo || !item.ProjNm || !item.RspnEmpNm)) {
            alert("과제 정보를 선택해주세요.");
            taskRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }); // 해당 위치로 스크롤
            return false;
        }

        // 연구 방법 요약
        if (!methodSummary) {
            alert("연구 방법 요약을 작성해주세요.");
            methodSummaryRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false;
        }

        // newDataSummary가 비어있거나 특정 필드가 없는 경우 유효성 실패
        if (newDataSummary.length === 0 || newDataSummary.some(item => !item.Summary?.DtaSetNm || !item.Summary?.DtaNm || !item.Summary?.DtaDs)) {
            alert("데이터 세트의 Summary 값이 비어있습니다.");
            newDataSummaryRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false;
        }

        if (Array.isArray(newDataSummary?.DataMain) && newDataSummary.DataMain.length === 0) {
            alert("데이터세트에 값이 없습니다.");
            newDataSetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false;
        }

        // 생산 계획 유효성 검사
        if (newProductionPlandataSets.length === 0 || newProductionPlandataSets.some(item => !item.dataType || !item.count || !item.proPlan)) {
            alert("생산 계획에 데이터가 없습니다.");
            productionPlanRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center'});
            return false;
        }

        // 연구 데이터 분석 계획
        if (!analysisPlan) {
            alert("연구데이터 분석 계획을 작성해주세요.");
            analysisPlanRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false;
        }

        // 연구 데이터 관리 계획 유효성 검사
        if (newManagementPlandataSets.length === 0 || newManagementPlandataSets.some(item => !item.dataType || !item.method)) {
            alert("연구데이터 관리 계획에 데이터가 없습니다.");
            managementPlanRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center'});
            return false;
        }

        // 연구 데이터 공개 계획 유효성 검사
        if (newPublicPlandataSets.length === 0 || newPublicPlandataSets.some(item => !item.datatype || !item.publictime)) {
            alert("연구데이터 공개 계획에 데이터가 없습니다.");
            publicPlanRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center'});
            return false;
        }

        return true;
    };
    const prepareProductionData = (tmpYn) => {
        if (!newProductionPlandataSets || newProductionPlandataSets.length === 0) {
            return [{
                PrdYear: '2024',
                DtaNm: '-',
                ProCnt: '-',
                ProPlan: '-'
            }];
        }

        return newProductionPlandataSets.map((newData) => ({
            PrdYear: "2024",
            DtaNm: tmpYn === "Y" ? (newData.dataType || '-') : newData.dataType,
            ProCnt: tmpYn === "Y" ? (newData.count || '-') : newData.count,
            ProPlan: tmpYn === "Y" ? (newData.proPlan || '-') : newData.proPlan
        }));
    };

    const prepareManageData = (tmpYn) => {
        if (!newManagementPlandataSets || newManagementPlandataSets.length === 0) {
            return [{
                DtaNm: '-',
                MngMth: '-',
                MngResn: '-',
                MngPos: '-',
                MngPd: '-',
                MngOpnMth: '-',
            }];
        }

        return newManagementPlandataSets.map((newData) => ({
            DtaNm: tmpYn === "Y" ? (newData.dataType || '-') : newData.dataType,
            MngMth: tmpYn === "Y" ? (newData.method || '-') : newData.method,
            MngResn: tmpYn === "Y" ? (newData.individualManagement || '-') : newData.individualManagement,
            MngPos: tmpYn === "Y" ? (newData.dataLocation || '-') : newData.dataLocation,
            MngPd: tmpYn === "Y" ? (newData.storagePeriod || '-') : newData.storagePeriod,
            MngOpnMth: tmpYn === "Y" ? (newData.periodExpires || '-') : newData.periodExpires
        }));
    };

    const preparePublicData = (tmpYn) => {
        if (!newPublicPlandataSets || newPublicPlandataSets.length === 0) {
            return [{
                DtaNm: '-',
                OpnTm: '-',
                OpnPd: '-',
                OpnMth: '-',
                OpnResn: '-',
                OpnOpnMth: '-',
            }];
        }

        return newPublicPlandataSets.map((newData) => ({
            DtaNm: tmpYn === "Y" ? (newData.datatype || '-') : newData.datatype,
            OpnTm: tmpYn === "Y" ? (newData.publictime || '-') : newData.publictime,
            OpnPd: tmpYn === "Y" ? (newData.externalTime || '-') : newData.externalTime,
            OpnMth: tmpYn === "Y" ? (newData.publicmethod || '-') : newData.publicmethod,
            OpnResn: tmpYn === "Y" ? (newData.individualplan || '-') : newData.individualplan,
            OpnOpnMth: tmpYn === "Y" ? (newData.individualmethod || '-') : newData.individualmethod
        }));
    };


    const prepareMaterialData = (tmpYn) => {
        const mapDataItem = (dataItem) => {
            // DataMain이 없거나 비어 있을 때 기본값을 사용
            const defaultDataMain = [{
                DtaFld: '-',
                DtaTyp: '-',
                DtaUnit: '-',
                DtaMtdIdx: '-',
                DtaMtdNm: '-',
                DtaDsc: '-',
                UserNo: '-',
                UserNm: '-'
            }];

            return {
                DtaSetNm: tmpYn === "Y" ? (dataItem.Summary?.DtaSetNm || '-') : dataItem.Summary?.DtaSetNm,
                DtaSetNmSub: tmpYn === "Y" ? (dataItem.Summary?.DtaSetNmSub || '-') : dataItem.Summary?.DtaSetNmSub,
                DtaNm: tmpYn === "Y" ? (dataItem.Summary?.DtaNm || '-') : dataItem.Summary?.DtaNm,
                DtaDs: tmpYn === "Y" ? (dataItem.Summary?.DtaDs || '-') : dataItem.Summary?.DtaDs,
                DmpFlg: tmpYn === "Y" ? (dataItem.Summary?.DmpFlg || '-') : dataItem.Summary?.DmpFlg,
                DataMain: (dataItem.DataMain && dataItem.DataMain.length > 0)
                    ? dataItem.DataMain.map((item) => ({
                        DtaFld: tmpYn === "Y" ? (item.field || item.DtaFld || '-') : (item.field || item.DtaFld),
                        DtaTyp: tmpYn === "Y" ? (item.type || item.DtaTyp || '-') : (item.type || item.DtaTyp),
                        DtaUnit: tmpYn === "Y" ? (item.unit || item.DtaUnit || '-') : (item.unit || item.DtaUnit),
                        DtaMtdIdx: tmpYn === "Y" ? (item.index || item.DtaMtdIdx || '-') : (item.index || item.DtaMtdIdx),
                        DtaMtdNm: tmpYn === "Y" ? (item.name || item.DtaMtdNm || '-') : (item.name || item.DtaMtdNm),
                        DtaDsc: tmpYn === "Y" ? (item.description || item.methoddescription || '-') : (item.description || item.methoddescription),
                        UserNo: tmpYn === "Y" ? (item.UserNo || '-') : item.UserNo,
                        UserNm: tmpYn === "Y" ? (item.UserNm || '-') : item.UserNm
                    }))
                    : defaultDataMain // DataMain이 없거나 비어 있을 경우 기본값 사용
            };
        };

        // lastDataSets가 배열이고 비어있지 않다면 변환
        if (Array.isArray(lastDataSets) && lastDataSets.length > 0) {
            return lastDataSets.map(mapDataItem);
        }

        // 그 외의 경우 newDataSummary를 변환
        return newDataSummary.map(mapDataItem);
    };



// Common function to make the API call
    const postData = (tmpYn, returnString) => {
        const productionPnNewData = prepareProductionData(tmpYn);
        const managePNNewData = prepareManageData(tmpYn);
        const publicNewData = preparePublicData(tmpYn);
        const materialDataSetValues = prepareMaterialData(tmpYn);


        console.log("데이터 확인합니다@@@@@@@@@@@@@@@@@@@",  materialDataSetValues);

        // 저장 시 필수 값 검증 (tmpYn === "N"일 때만 검증)
        if (tmpYn === "N" && !validateData()) {
            return; // 검증에 실패하면 저장 중단
        }

        // API 요청
        api.post('/api/dmp/saveDmp', {
            DmpMain: {
                ProjNo: createProjNo || "-",
                ProjNm: createProName || "-",
                RspnEmpNo: createRspnEmpNo || "-",
                RspnEmpNm: rspnEmpNm || "-",
                DataEmpNo: createDateDmpNo || "-",
                DataEmpNm: createDateDmpNm || "-",
                RsrcSmm: methodSummary || "-",
                RsrcImg: "",
                RsrcFileNm: "",
                RsrcFilePath: "",
                RsrcAnlyPlan: analysisPlan || "-",
                TmpYn: tmpYn
            },
            Summary: materialDataSetValues,
            Prod: productionPnNewData,
            Mnmt: managePNNewData,
            Open: publicNewData
        }, { withCredentials: true })
            .then((res) => {
                alert(returnString);
                navigate("/dmpManagement/dmpList/dmplist");
            })
            .catch((error) => {
                console.error("API 통신 에러 발생: ", error.response ? error.response.data : "No response data");
                alert("작성하신 dmp를 확인해주세요.");
            });
    };

// 저장 버튼 (필수 입력 값이 없으면 저장 불가)
    const DataSaveBtn = (e) => {
        e.preventDefault();
        postData("N", "저장 성공");
    };

// 임시 저장 버튼 (빈 값은 '-'로 처리)
    const DataHisBtn = (e) => {
        e.preventDefault();
        postData("Y", "임시저장 성공");
    };

    return(
        <>
            <div className="dmp-banner-container">
                <div className="dmp-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmp-img02" alt="search Logo" />
                    <div className="banner-text">
                        <span>데이터 관리 계획</span>
                    </div>
                    <div className="dmp-description">
                        <div className="dmp-description02">
                            <img  src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 관리 계획 > 데이터 관리 계획 등록</span>
                        </div>
                    </div>
                </div>


                <section>
                    <div className="management-plan-title">
                        <h1>데이터 관리 계획</h1>
                        <div>
                            <span className="required_color">*</span>
                            <span>필수 항목</span>
                        </div>
                    </div>
                    <div className="under_line"></div>
                    <div className="object-info">
                        <div className="object-info-title">
                            <span className="info">과제 정보</span>
                            <span className="required_color">*</span>
                        </div>
                        <div className="task-title">
                            <div className="task-wrapper">
                                    <>
                                        <button className="check-obj" onClick={toggleObjCheck}>과제 선택</button>
                                        {showObjCheck && <TaskCheck />}
                                    </>

                                    {
                                            tastdataSets?.length > 0 || lastDataCellValue? (
                                        <div key={tastdataSets[0]?.DmpNo} className="objCheck-name">
                                            <input
                                                type="text"
                                                className="edit--box"
                                                ref={taskRef}
                                                value={createProjNo}
                                                onChange={(e) => setCreateProjNo(e.target.value)}
                                                readOnly
                                                placeholder="과제번호"
                                            />
                                            <input
                                                type="text"
                                                className="edit--box"
                                                ref={taskRef}
                                                value={createProName}
                                                onChange={(e) => setCreateProName(e.target.value)}
                                                readOnly
                                                placeholder="과제명"
                                            />
                                            <input
                                                type="text"
                                                className="edit--box"
                                                ref={taskRef}
                                                value={rspnEmpNm}
                                                onChange={(e) => setRspnEmpNm(e.target.value)}
                                                readOnly
                                                placeholder="연구책임자"
                                            />
                                        </div>
                                    ) : (
                                        // 모든 조건이 만족되지 않을 경우 빈 입력 창을 보여줌
                                        <>
                                            <input
                                                type="text"
                                                ref={taskRef}
                                                className="edit--box"
                                                value={''}
                                                placeholder="과제번호"
                                            />
                                            <input
                                                type="text"
                                                ref={taskRef}
                                                className="edit--box"
                                                value={''}
                                                placeholder="과제명"
                                            />
                                            <input
                                                type="text"
                                                ref={taskRef}
                                                className="edit--box"
                                                value={''}
                                                placeholder="연구책임자"
                                            />
                                        </>
                                    )}
                                        <>
                                            <button className="last-dmp" type="button" onClick={toggleLastDmp}>지난 DMP 불러오기</button>
                                            {showLastDmp && <LastDmpShow />}
                                            <button className="history-dmp" type="button" onClick={toggleHistoryDmp}>임시저장 DMP 불러오기</button>
                                            {showHistory && <LastDmpShow setShowHistory = {showHistory}/>}
                                        </>

                                </div>
                                <div>
                                    <span>* 과제 번호를 선택하면 과제 정보가 자동으로 입력됩니다.</span>
                                </div>
                            </div>
                        </div>
                        <div className="start_line"></div>
                            <div className="research-method-info">
                                <div className="object-info-title">
                                    <span className="info">연구 방법 요약</span>
                                    <span className="required_color">*</span>
                                </div>
                                <div className="research-method-title">
                                    <textarea
                                        type="text"
                                        ref={methodSummaryRef}
                                        value={methodSummary}
                                        onChange={(e)=> setMethodSummary(e.target.value)}
                                        className="edit--box"
                                        placeholder="연구방법을 서술형으로 작성하세요."
                                    />
                                </div>
                            </div>
                                <div className="start_line"></div>
                                <div className="configuring-research-data-info">
                                    <div className="object-info-title">
                                        <span className="info">연구데이터 구성</span>
                                    </div>
                                    <div className="research-method-title">
                                        <label htmlFor="fileDiagram">
                                            <img id="imgDiagram" src={imageSrc} alt="Diagram" />
                                        </label>
                                        <input
                                            type="file"
                                            id="fileDiagram"
                                            name="fileDiagram"
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange}
                                        />
                                        <input type="text" id="txtDiagram" name="txtDiagram" style={{ display: 'none' }} />

                                    </div>
                                </div>
                                <div className="start_line"></div>

                                <div className="data-set-container" >
                                    {lastDataSets? (
                                        // lastDataCellValue.SumData가 존재할 때
                                        lastDataSets.map((item, index) => (
                                            <div className="data-set-info" key={index}>
                                                <div className="data-set-title-wrapper">
                                                    <div className="data-set-title">
                                                        <span className="info">데이터 세트</span>
                                                        <span className="required_color">*</span>
                                                        <button className="data-set-delete" type="button" onClick={() => newHandleDeleteDataSet(item.Summary.DtaSetNo)}>
                                                            <span>-</span>
                                                            <span>삭제</span>
                                                        </button>
                                                    </div>
                                                    <div className="selectbox-container">
                                                        <div className="selectbox-check">
                                                            <select
                                                                className="resultData"
                                                                value={item.Summary.DtaSetNm}
                                                                onChange={(e) => newSummaryhandleChange(item.Summary.DtaSetNo || item.Summary.id, 'DtaSetNm', e.target.value)}
                                                            >
                                                                <option value=''>-- 구분 --</option>
                                                                <option value="시료데이터">시료데이터</option>
                                                                <option value="방법데이터">방법데이터</option>
                                                                <option value="결과데이터">결과데이터</option>
                                                            </select>
                                                            <select
                                                                className="resultData"
                                                                id="selectBox"
                                                                value={item.Summary.DtaSetNmSub}
                                                                onChange={(e) => newSummaryhandleChange(item.Summary.DtaSetNo || item.Summary.id, 'DtaSetNmSub', e.target.value)}
                                                            >
                                                                <option value=''>-- 선택 * 필수 --</option>
                                                                <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                                <option value="Food, Processed">Food, Processed</option>
                                                                <option value="Food, Derived">Food, Derived</option>
                                                                <option value="Animal">Animal</option>
                                                                <option value="Human">Human</option>
                                                                <option value="Invertebrate">Invertebrate</option>
                                                                <option value="Microbe">Microbe</option>
                                                                <option value="Cell, Animal">Cell, Animal</option>
                                                                <option value="Cell, Human">Cell, Human</option>
                                                                <option value="Cell, Plant">Cell, Plant</option>
                                                                <option value="etc">etc</option>
                                                            </select>

                                                            <input
                                                                type="text"
                                                                className="edit--box"
                                                                value={item.Summary.DtaNm}
                                                                onChange={(e) => newSummaryhandleChange(item.Summary.DtaSetNo || item.Summary.id, 'DtaNm', e.target.value)}
                                                                id="data-type-name"
                                                                name="DtaNm"
                                                                placeholder="데이터 타입명"
                                                            />
                                                            <input type="text"
                                                                   className="edit--box"
                                                                   value={item.Summary.DtaDs}
                                                                   onChange={(e) => newSummaryhandleChange(item.Summary.DtaSetNo || item.Summary.id, 'DtaDs', e.target.value)}
                                                                   id="data-type-desc"
                                                                   name="DtaDs"
                                                                   placeholder="데이터 타입 설명"
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="toastui-wrapper">
                                                    <DmpToastGrid MethodData={item.Summary.DtaSetNm} DateSets={item}/>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        // newDataSummary가 존재할 때
                                        newDataSummary && newDataSummary.map((newSetData, index) => (
                                            <div className="data-set-info" key={index}>
                                                <div className="data-set-title-wrapper">
                                                    <div className="data-set-title">
                                                        <span className="info">데이터 세트</span>
                                                        <span className="required_color">*</span>
                                                        <button className="data-set-delete" type="button" onClick={() => newHandleDeleteDataSet(newSetData.Summary?.id)}>
                                                            <span>-</span>
                                                            <span>삭제</span>
                                                        </button>
                                                    </div>
                                                    <div className="selectbox-container"  ref = {newDataSummaryRef}>
                                                        <div className="selectbox-check">
                                                            <select
                                                                className="resultData"
                                                                value={newSetData.Summary?.DtaSetNm}
                                                                onChange={(e) => newSummaryhandleChange(newSetData.Summary?.id, 'DtaSetNm', e.target.value)}
                                                            >
                                                                <option value=''>-- 구분 --</option>
                                                                <option value="시료데이터">시료데이터</option>
                                                                <option value="방법데이터">방법데이터</option>
                                                                <option value="결과데이터">결과데이터</option>
                                                            </select>
                                                            <select
                                                                className="resultData"
                                                                id="selectBox"
                                                                value={newSetData.Summary?.DtaSetNmSub}
                                                                onChange={(e) => newSummaryhandleChange(newSetData.Summary?.id, 'DtaSetNmSub', e.target.value)}
                                                            >
                                                                <option value=''>-- 선택 * 필수 --</option>
                                                                <option value="Food, Unprocessed">Food, Unprocessed</option>
                                                                <option value="Food, Processed">Food, Processed</option>
                                                                <option value="Food, Derived">Food, Derived</option>
                                                                <option value="Animal">Animal</option>
                                                                <option value="Human">Human</option>
                                                                <option value="Invertebrate">Invertebrate</option>
                                                                <option value="Microbe">Microbe</option>
                                                                <option value="Cell, Animal">Cell, Animal</option>
                                                                <option value="Cell, Human">Cell, Human</option>
                                                                <option value="Cell, Plant">Cell, Plant</option>
                                                                <option value="etc">etc</option>
                                                            </select>

                                                            <input
                                                                type="text"
                                                                className="edit--box"
                                                                value={newSetData.Summary?.DtaNm}
                                                                onChange={(e) => newSummaryhandleChange(newSetData.Summary?.id, 'DtaNm', e.target.value)}
                                                                id="data-type-name"
                                                                name="DtaNm"
                                                                placeholder="데이터 타입명"
                                                            />
                                                            <input type="text"
                                                                   className="edit--box"
                                                                   value={newSetData.Summary?.DtaDs}
                                                                   onChange={(e) => newSummaryhandleChange(newSetData.Summary?.id, 'DtaDs', e.target.value)}
                                                                   id="data-type-desc"
                                                                   name="DtaDs"
                                                                   placeholder="데이터 타입 설명"
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="toastui-wrapper" ref={newDataSetRef}>
                                                    <DmpToastGrid MethodData={newSetData.Summary?.DtaSetNm} DateSets={newSetData} />
                                                </div>
                                            </div>
                                        ))
                                    )}

                                    <button className="data-set-add" type="button" onClick={handleCreateAddDataSet}>{`+ ${resultSummaryType} 세트 양식 추가`}</button>
                                </div>
                        <div className="start_line"></div>
                        <div className="data-management-info">
                            <div className="object-info-title">
                                <span className="info">생산 계획</span>
                                <span className="required_color">*</span>
                            </div>
                                <div ref={productionPlanRef}>
                                    {
                                        lastDataCellValue ? (
                                        <>
                                            <DmpDataProductionPlan updateProductionPn={lastDataCellValue}  />
                                        </>
                                    ) : (
                                        <DmpDataProductionPlan />
                                        )
                                    }
                                </div>

                             </div>
                        <div className="start_line"></div>

                        <div className="data-analysis-info">
                            <div className="object-info-title">
                                <span className="info">연구데이터 분석 계획</span>
                                <span className="required_color">*</span>
                            </div>

                                    <div className="research-method-title">
                                        <textarea type="text"
                                                  className="edit--box"
                                                  ref={analysisPlanRef}
                                                  value={analysisPlan}
                                                  onChange={(e) => setAnalysisPlan(e.target.value)}
                                                  placeholder="연구데이터 분석 계획을 서술형으로 작성하세요."></textarea>
                                    </div>
                        </div>
                        <div className="start_line"></div>
                        <div className="data-management-info">
                            <div className="object-info-title">
                                <span className="info">연구데이터 관리 계획</span>
                                <span className="required_color">*</span>
                            </div>
                                <div ref={managementPlanRef}>
                                    {
                                        lastDataCellValue ? (
                                            <DataManagementGrid updateProductionPn={lastDataCellValue} />
                                        ): (
                                            <DataManagementGrid/>
                                        )
                                    }
                                </div>
                        </div>
                        <div className="start_line"></div>

                        <div className="data-public-info">
                            <div className="object-info-title">
                                <span className="info">연구데이터 공개 계획</span>
                                <span className="required_color">*</span>
                            </div>
                                <div ref={publicPlanRef}>
                                    {
                                        lastDataCellValue ? (
                                            <DataPublicGrid updateProductionPn ={lastDataCellValue} />
                                        ) : (
                                            <DataPublicGrid/>
                                        )
                                    }
                                </div>
                        </div>
                        <div className="start_line"></div>

                        <div className="save-container">
                            <button id="dmp_list" type="button">
                                <Link to="/dmpManagement/dmpList/dmplist">목록</Link>
                            </button>
                            <button id="save-btn" onClick={DataSaveBtn}>
                                저장
                            </button>
                            <button id="his-btn" onClick={DataHisBtn}>
                                임시저장
                            </button>
                        </div>
                </section>

            </div>
        </>
    )
}
export default DmpcreatePage;