import React, { useEffect, useState } from "react";
import './dmpsend.css';
import api from '../../api';

function Dmpsend() {
    const [dmpsend, setDmpSend] = useState([]); // DMP 목록
    const [dmpsendValue, setDmpsendValue] = useState(0); // 총 DMP 값
    const [dmpDownload, setDmpDownload] = useState(false); // 다운로드 여부
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 번호
    const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수
    const pageSize = 10; // 한 페이지에 표시할 DMP 수
    const pageGroupSize = 10; // 페이지네이션 그룹 크기

    // DMP 리스트를 불러오는 함수
    useEffect(() => {
        api.get(`/api/dmp/dmplist?kw&ps=${currentPage}&pl=${pageSize}&pg=${'SBM'}`, {
            withCredentials: true
        }).then(res => {
            setDmpSend(res.data.items);
            setDmpsendValue(res.data.pages.Total); // 총 DMP 개수 설정
            setTotalPages(Math.ceil(res.data.pages.Total / pageSize)); // 전체 페이지 수 계산
        });
    }, [currentPage]);


    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize, dmpsendValue);
        return dmpsend.slice(0, endIndex - startIndex);
    };

    const dmpHwpDownload = (name, id) => {
        console.log("이름 값", name)
        api.get(`/api/hwp/${name}?DmpNo=${id}`, {
            withCredentials: true,
            responseType: 'blob'  // 응답을 blob으로 받기 위해 설정
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to download file, status code: ' + res.status);
                }
                return res.data;  // blob 데이터
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${name}.hwp`; // 파일명
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                setDmpDownload(true);
            })
            .catch(error => {
                console.error('Error downloading HWP file:', error);
            });
    };

    const dmpSendHandler = (id) => {
        if (!dmpDownload) {
            alert("DMP 제출 전 문서를 다운로드 하여 내용을 확인해주세요.\nDMP가 제출 된 후 수정을 원하시는 경우 지능화정책팀으로 문의하여 주시기 바랍니다.");
            return;
        }

        api.put(`/api/dmp/dmpsubmit?DmpNo=${id}`).then((res => alert(res.data)));
    };

    // 페이지네이션을 렌더링하는 함수
    const renderPagination = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${i === currentPage ? 'active' : ''}`}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => setCurrentPage(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => setCurrentPage(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    console.log("dmpsenddmpsend==", dmpsend);

    return (
        <>
            <div className="dmpsend-container">
                <div className="dmpsend-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmpsend-img02" alt="search Logo" />
                    <div className="banner-text">
                        <span>데이터 관리 계획(DMP 제출)</span>
                    </div>
                    <div className="dmpsend-description">
                        <div className="dmpsend-description02">
                            <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 데이터 관리 계획 > DMP 제출</span>
                        </div>
                    </div>
                </div>
                <div className="dmp-sendcontainer">
                    <div>
                        <div>총 {dmpsendValue}건</div>
                    </div>
                    <div className="dmpsend-start_line"></div>

                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '30px' }}>
                        <thead>
                        <tr className="dmpsend-title">
                            <th>과제번호</th>
                            <th>연차</th>
                            <th>과제명</th>
                            <th>연구 책임자</th>
                            <th>DMP 문서 다운로드</th>
                            <th>DMP 제출상태</th>
                            <th>마지막 수정일</th>
                        </tr>
                        </thead>
                        <tbody>
                        {getPaginatedData().map((row, index) => (
                            <tr key={index} className="dmpsend-Data">
                                <td>{row.ProjNo}</td>
                                <td>{row.ProjAnnl}년차</td>
                                <td>{row.ProjNm}</td>
                                <td>{row.RsPnEmpNm}</td>
                                <td>
                                    <button className="download-button" onClick={() => dmpHwpDownload(row.ProjNm, row.DmpNo)}>다운로드</button>
                                </td>
                                <td>
                                    {row.SubmitStatus === '1' ? (
                                        <button className="dmpsend-btn" onClick={() => dmpSendHandler(row.DmpNo)}>DMP 제출하기</button>
                                    ) : row.SubmitStatus === '2' ? (
                                        <button className="dmpsend-btn" disabled>제출</button>
                                    ) : row.SubmitStatus === '3' ? (
                                        <button className="dmpsend-status" disabled>검토완료</button>
                                    ) : row.SubmitStatus === '4' ? (
                                        <button className="dmpsend-status" disabled>검토완료(의견있음)</button>
                                    ) : null }
                                </td>


                                <td>{row.UpdDt}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/* 페이지네이션 추가 */}
                    {renderPagination()}
                </div>
            </div>
        </>
    );
}

export default Dmpsend;
