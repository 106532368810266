import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import ApplicationPage from "../application/applicationPage";
import { debounce } from "lodash";

function OpenData() {
    const [dataList, setDataList] = useState([]);
    const [dataCount, setDataCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState(""); // 검색어 상태 추가
    const [selectedProjects, setSelectedProjects] = useState([]); // DtaSbmSeqNo 기준으로 저장
    const pageSize = 10;
    const pageGroupSize = 10;
    const navigate = useNavigate();
    const [showPage, setShowPage] = useState(false);

    // 데이터 가져오는 함수 (검색어와 페이지를 인자로 받음)
    const fetchData = async (searchTerm = "", page = 1) => {
        try {
            const res = await api.get(`/api/open/list?kw=${searchTerm}&ps=${page}&pl=${pageSize}`, {
                withCredentials: true,
            });
            setDataList(res.data.item);
            setDataCount(res.data.Pages.Total);
            setTotalPages(Math.ceil(res.data.Pages.Total / pageSize)); // 전체 페이지 수 계산
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // 첫 페이지 로드 시 데이터 가져오기
    useEffect(() => {
        fetchData(searchTerm, currentPage);
    }, [currentPage, searchTerm]);

    // 디바운스된 함수 생성 (300ms)
    const debouncedFetchData = useCallback(
        debounce((value) => {
            setCurrentPage(1); // 검색 시 페이지를 1로 리셋
            fetchData(value, 1); // 검색어와 1페이지로 API 호출
        }, 300),
        []
    );

    // 검색어 입력 처리
    const handlerInputChange = (event) => {
        const value = event.target.value;

        // 입력된 값의 길이가 14자를 넘으면 alert를 띄우고 값을 초기화
        if (value.length > 14) {
            alert("입력 가능한 최대 길이는 14자입니다.");
            setSearchTerm(""); // 값을 초기화
            event.target.value = ""; // input 필드도 초기화
            return;
        }

        setSearchTerm(value); // 검색어 상태 업데이트
        debouncedFetchData(value); // 디바운스된 API 호출
    };

    // 현재 페이지에 맞는 데이터 가져오기
    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize, dataCount);
        return dataList.slice(0, endIndex - startIndex);
    };

    // 체크박스 선택 시 처리 함수
    const handleCheckboxChange = (row) => {
        const alreadySelected = selectedProjects.find(project => project.DtaSbmSeqNo === row.DtaSbmSeqNo);

        if (alreadySelected) {
            // 선택 해제할 경우 배열에서 제거
            setSelectedProjects(selectedProjects.filter(project => project.DtaSbmSeqNo !== row.DtaSbmSeqNo));
        } else {
            // 선택된 경우 배열에 추가
            setSelectedProjects([...selectedProjects, row]);
        }
    };

    // 연구데이터 신청서 작성하기 버튼 클릭 시
    const handleSubmitApplication = () => {
        if (selectedProjects.length === 0) {
            alert('하나 이상의 과제를 선택해주세요.');
            return;
        } else if (selectedProjects.length > 3) {
            alert("3개 이상 선택 하실 수 없습니다.");
            setSelectedProjects([]);
            return;
        }
        console.log("선택된 프로젝트들: ", selectedProjects); // 선택된 프로젝트들 출력
        setShowPage(true);
    };

    // 페이지네이션 렌더링 함수
    const renderPagination = () => {
        const startPage = Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
        const endPage = Math.min(startPage + pageGroupSize - 1, totalPages);

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    className={`pagination-button ${i === currentPage ? 'active' : ''}`}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination">
                {startPage > 1 && (
                    <button className="pagination-button" onClick={() => setCurrentPage(startPage - 1)}>
                        이전
                    </button>
                )}
                {pages}
                {endPage < totalPages && (
                    <button className="pagination-button" onClick={() => setCurrentPage(endPage + 1)}>
                        다음
                    </button>
                )}
            </div>
        );
    };

    const openDataRowClick = (DtaSbmSeqNo) => {
        navigate(`/openData/detail/page/${DtaSbmSeqNo}`);
    };

    return (
        <>
            <div className="dmpReallist-container">
                <div className="dmpReallist-banner-img">
                    <img src={`${process.env.PUBLIC_URL}/bg_dmp.png`} className="dmpReallist-img02" alt="search Logo" />
                    <div className="banner-Realtext">
                        <span>공개 데이터</span>
                    </div>
                    <div className="dmpReallist-description">
                        <div className="dmpReallist-description02">
                            <img src={`${process.env.PUBLIC_URL}/icon_sub_navi_home.png`} alt="search Logo" />
                            <span> > 공개데이터 > 공개데이터 목록</span>
                        </div>
                    </div>
                </div>
                <div className="dmpReallist-inputbox">
                    <img src={`${process.env.PUBLIC_URL}/icon_search.png`} alt="search Logo" />
                    <input type="text" placeholder="검색어를 입력해주세요."
                           value={searchTerm}
                           onChange={handlerInputChange}
                    />
                </div>
                <div>
                    <div className="btn-Realcontainer">
                        <div>총 {dataCount}건</div>
                        <div className="btn-Realcheck">
                            <button className="btn-modalOpenData" onClick={handleSubmitApplication}>
                                연구데이터 이용 신청서 작성하기
                            </button>
                            <button className="btn-oath">
                                연구데이터 이용 서약서 다운로드
                            </button>
                            {showPage && <ApplicationPage setShowPage={setShowPage} selectedProjects={selectedProjects}/>}
                        </div>
                    </div>
                    <div className="dmpReallist-start_line"></div>

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                        <tr className="dmplist-title">
                            <th>선택</th>
                            <th className="obn01">과제명</th>
                            <th className="obn02">데이터셋 / 설명</th>
                            <th className="obn04">연구 책임자</th>
                            <th className="obn05">데이터 상태</th>
                        </tr>
                        </thead>
                        <tbody>
                        {getPaginatedData().map((row, index) => (
                            <tr key={index} className="dmplist-Data">
                                <td  style={{ width: '5%' }}>
                                    <input
                                        style={{ height: '20px' }}
                                        type="checkbox"
                                        onChange={() => handleCheckboxChange(row)}
                                        checked={!!selectedProjects.find(project => project.DtaSbmSeqNo === row.DtaSbmSeqNo)}
                                    />
                                </td>
                                <td>{row.ProjNm}</td>
                                <td onClick={() => openDataRowClick(row.DtaSbmSeqNo)}>
                                    {row.DtaSetNm} / {row.DtaDs}
                                </td>
                                <td>{row.DtaNm}</td>
                                <td>
                                    {
                                        row.OpenStatus === "1" ? (
                                            <>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/unlock.png`}
                                                    style={{ width: '20px', marginRight: '5px', verticalAlign: 'text-bottom' }}
                                                    alt="unlock Logo"
                                                />
                                                <span>공개</span>
                                            </>
                                        ) : (
                                            <>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/icon_lock.png`}
                                                    style={{ width: '25px', marginRight: '5px', verticalAlign: 'text-bottom' }}
                                                    alt="lock Logo"
                                                />
                                                <span>비공개</span>
                                            </>
                                        )
                                    }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/* 페이지네이션 추가 */}
                    {renderPagination()}
                </div>
            </div>
        </>
    );
}

export default OpenData;
